import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { getTextColorByBackground } from 'utils/ColorUtil'
import { Grid, Icon, Popover, Tooltip } from '@mui/material'
import { disabledColor, LARGE_RADIUS, mainWhite, MEDIUM_RADIUS } from 'components/styled/Theme'
import { HISTO, J30, J365 } from './ChartFollowContants'
import HomeAction from 'pages/home/actions/HomeAction'
import { CHART_SELECTED_TIME } from 'pages/home/constants/HomeConstants'
import { useDispatch } from 'react-redux'
import { DISPLAY_COTE, MEASURE_COTE, PIEZO_TAB_DISPLAY_MODES } from './ChartConstant'
import SimpleCheckbox from '../forms/SimpleCheckbox'
import { getLocalStorageJson, setLocalStorageJson } from 'utils/LocalStorageUtils'
import { ButtonMUI } from 'components/styled/Buttons'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../styled/dialog'
import { groupBy, keys, maxBy, orderBy } from 'lodash'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import { mainBlue } from 'components/styled/Theme'
import DtoMeasureStats from 'pages/online/follows/dto/DtoMeasureStats'
import SortIcon from '@mui/icons-material/Sort'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { getI18nOrLabel } from 'utils/StringUtil'
import { getSubstractTime } from './EChartUtils'
import moment from 'moment'

const CHRONIC_TYPE = -1
const MAX_POPOVER_HEIGHT = 400

const TooManyDataDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
}) => (
    <DialogMUI open={isOpen} PaperProps={{ sx: { minHeight: 'auto', maxHeight: 'auto' } }}>
        <DialogTitleMUI sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item>
                <h3 style={{ margin: 0, fontWeight: '600', fontSize: '20px' }}>{i18n.payAttention}</h3>
            </Grid>
            <Grid item>
                <Icon
                    sx={{ color: mainWhite, cursor: 'pointer' }}
                    size='small'
                    icon='close'
                    onClick={onClose}
                >
                        close
                </Icon>
            </Grid>
        </DialogTitleMUI>
        <DialogContentMUI>
            <Grid container>
                <Grid item xs={12} sx={{ padding: '1rem 0' }}>
                    {i18n.tooManyDataConfirmationMessage}
                </Grid>
            </Grid>
        </DialogContentMUI>
        <DialogActionsMUI>
            <Grid container item xs={12} justifyContent='flex-end' alignItems='center'>
                <Grid item>
                    <ButtonMUI
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            onValidate()
                            onClose()
                        }}
                    >
                        {i18n.validate}
                    </ButtonMUI>
                </Grid>
            </Grid>
        </DialogActionsMUI>
    </DialogMUI>
)

TooManyDataDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
}

const initialDisplayMode = {
    auto: true,
    brute: false,
    min: false,
    max: false,
    average: false,
}

const ChartTabs = ({
    time = 30,
    onChangeTime = () => {},

    statistics = [],
    stationType,

    setDisplayCote = () => {},
    setDiplayModes = () => {},

    inprogress = false,

    dataTypes = [],
    setDataTypes = () => {},
}) => {
    const defaultDisplayCote = useMemo(() => getLocalStorageJson(DISPLAY_COTE) || undefined, [])
    const defaultDisplayMode = useMemo(() => getLocalStorageJson(PIEZO_TAB_DISPLAY_MODES) || initialDisplayMode, [])

    const [settingsEl, setSettingsEl] = useState(undefined)
    const [dataTypeEl, setDataTypeEl] = useState(undefined)
    const [tempDisplayCote, setTempDisplayCote] = useState(defaultDisplayCote)
    const [tempDisplayModes, setTempDiplayModes] = useState(defaultDisplayMode)

    const [tempDataTypes, setTempDataTypes] = useState(dataTypes)

    const [isOpenDisplayMode, setIsOpenDisplayMode] = useState(false)
    const [isOpenHisto, setIsOpenHisto] = useState(false)

    const getBackgroundColor = (timeScale) => time === timeScale ? mainBlue : mainWhite

    const dispatch = useDispatch()

    const onChange = (newTime) => {
        const minDate = newTime !== HISTO ? getSubstractTime(newTime) : undefined
        const maxDate = newTime === HISTO ? moment().valueOf() : undefined
        onChangeTime({ minDate, maxDate, time: newTime })
        dispatch(HomeAction.updateSetting({ settingName: CHART_SELECTED_TIME, value: `${newTime}` }))
    }

    const onChangeDisplayMode = (changes) => {
        const newDisplay = { ...tempDisplayModes, ...changes }
        if (!newDisplay.brute && !newDisplay.min && !newDisplay.max && !newDisplay.average) {
            newDisplay.auto = true
        } else {
            newDisplay.auto = false
        }
        setLocalStorageJson(PIEZO_TAB_DISPLAY_MODES, newDisplay)
        setTempDiplayModes(newDisplay)
    }

    const roundDivStyle = {
        width: '1rem',
        height: '1rem',
        borderRadius: '50rem',
    }

    const onValidateDisplayMode = () => {
        setSettingsEl(undefined)
        setDisplayCote(tempDisplayCote)
        setDiplayModes(tempDisplayModes)
    }

    const checkConfirmation = (toCheck, setIsOpen = () => {}, cb = () => {}) => {
        if ([OBSERVATORY_STATION_TYPE_NAME.catchment, STATION_TYPE_NAME.piezometry].includes(stationType) && statistics.length) {
            const statBrute = statistics.find(stat => stat.typeId === CHRONIC_TYPE)
            if (toCheck && statBrute && statBrute.countTotal > 10000) {
                setIsOpen(true)
            } else {
                cb()
            }
        } else if ([STATION_TYPE_NAME.hydrometry, STATION_TYPE_NAME.pluviometry].includes(stationType) && statistics.length) {
            if (maxBy(statistics, c => c.countTotal).countTotal > 10000) {
                setIsOpen(true)
            } else {
                cb()
            }
        } else {
            cb()
        }
    }

    const isPiezo = [OBSERVATORY_STATION_TYPE_NAME.catchment, STATION_TYPE_NAME.piezometry].includes(stationType)
    const groupedDataTypes = groupBy(dataTypes, 'type')

    return (
        <Grid item xs={12}>
            <Grid
                container
                sx={{
                    width: 300,
                    margin: '10px',
                    boxShadow: '0px 0px 16px 0px rgb(0 0 0 / 12%)',
                    textAlign: 'center',
                    borderRadius: '24px',
                    overflow: 'hidden',
                    padding: '0.35rem',
                    backgroundColor: !inprogress ? mainWhite : disabledColor,
                }}
                alignItems='center'
                justifyContent='space-between'
            >
                <Grid
                    container item xs={(isPiezo || dataTypes.length) ? 10.25 : 12}
                    sx={{
                        '& div': {
                            lineHeight: '1.1',
                        },
                    }}
                >
                    <Grid
                        item
                        xs={4}
                        className='clickable'
                        onClick={() => !inprogress && onChange(J30)}
                        sx={{
                            padding: '0.35rem 0',
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: inprogress ? disabledColor : getBackgroundColor(J30),
                            color: getTextColorByBackground(getBackgroundColor(J30)),
                            borderRadius: LARGE_RADIUS,
                        }}
                    >
                                30J
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className='clickable'
                        onClick={() => !inprogress && onChange(J365)}
                        sx={{
                            padding: '0.35rem 0',
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: inprogress ? disabledColor : getBackgroundColor(J365),
                            color: getTextColorByBackground(getBackgroundColor(J365)),
                            borderRadius: LARGE_RADIUS,
                        }}
                    >
                                365J
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className='clickable'
                        onClick={() => !inprogress && checkConfirmation(true, setIsOpenHisto, () => onChange(HISTO))}
                        sx={{
                            padding: '0.35rem 0',
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: inprogress ? disabledColor : getBackgroundColor(HISTO),
                            color: getTextColorByBackground(getBackgroundColor(HISTO)),
                            borderRadius: LARGE_RADIUS,
                        }}
                    >
                                HISTO
                    </Grid>
                </Grid>
                {!!isPiezo && (
                    <Grid
                        item xs={1.5}
                        sx={{
                            padding: '0.25rem 0',
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: inprogress ? disabledColor : mainBlue,
                            color: mainBlue,
                            borderRadius: '50rem',
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                backgroundColor: inprogress ? disabledColor : mainBlue,
                            },
                        }}
                        onClick={event => !inprogress && setSettingsEl(event.currentTarget)}
                    >
                        <TroubleshootIcon sx={{ color: mainWhite }} />
                    </Grid>
                )}
                {!!dataTypes.length && (
                    <Grid
                        item xs={1.5}
                        sx={{
                            padding: '0.25rem 0',
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: inprogress ? disabledColor : mainBlue,
                            color: mainBlue,
                            borderRadius: '50rem',
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                backgroundColor: inprogress ? disabledColor : mainBlue,
                            },
                        }}
                        onClick={event => !inprogress && setDataTypeEl(event.currentTarget)}
                    >
                        <SortIcon sx={{ color: mainWhite }} />
                    </Grid>
                )}
            </Grid>
            {!!(isPiezo && !inprogress) && (
                <>
                    <Popover
                        open={!!settingsEl}
                        anchorEl={settingsEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{ borderRadius: MEDIUM_RADIUS, maxHeight: { xl: '100%', lg: MAX_POPOVER_HEIGHT, xs: 'auto' } }}
                        onClose={() => setSettingsEl(undefined)}
                    >
                        <Grid container sx={{ width: '300px', padding: '1rem' }}>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        label={i18n.depthLastMarker}
                                        labelStyle={{ fontWeight: 'normal' }}
                                        checked={tempDisplayCote === MEASURE_COTE.DEPTH}
                                        onToggle={() => setTempDisplayCote(MEASURE_COTE.DEPTH)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        label='NGF'
                                        labelStyle={{ fontWeight: 'normal' }}
                                        checked={tempDisplayCote === MEASURE_COTE.NGF}
                                        onToggle={() => setTempDisplayCote(MEASURE_COTE.NGF)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        label={i18n.ground}
                                        labelStyle={{ fontWeight: 'normal' }}
                                        checked={tempDisplayCote === MEASURE_COTE.GROUND}
                                        onToggle={() => setTempDisplayCote(MEASURE_COTE.GROUND)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        label={i18n.benchmarkHistory}
                                        labelStyle={{ fontWeight: 'normal' }}
                                        checked={tempDisplayCote === MEASURE_COTE.LANDMARK}
                                        onToggle={() => setTempDisplayCote(MEASURE_COTE.LANDMARK)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: '1rem', fontWeight: '600' }}>
                                <span>{i18n.choiceCurves}</span>
                            </Grid>
                            <Grid container item xs={12} sx={{ paddingTop: '1rem' }}>
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        label='Auto'
                                        labelStyle={{ fontWeight: 'normal' }}
                                        beforeLabel={(
                                            <div style={{ ...roundDivStyle, backgroundColor: 'black' }} />
                                        )}
                                        afterLabel={(
                                            <Tooltip
                                                placement='top'
                                                arrow
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            maxWidth: 'fit-content',
                                                            width: 'fit-content',
                                                            fontSize: '14px',
                                                        },
                                                    },
                                                }}
                                                title={i18n.descriptionCumulAuto}
                                            >
                                                <Icon sx={{ fontSize: '18px', color: 'grey' }}>info</Icon>
                                            </Tooltip>
                                        )}
                                        checked={tempDisplayModes.auto}
                                        onToggle={() => onChangeDisplayMode(initialDisplayMode)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        label={i18n.gross}
                                        labelStyle={{ fontWeight: 'normal' }}
                                        beforeLabel={(
                                            <div style={{ ...roundDivStyle, backgroundColor: 'black' }} />
                                        )}
                                        afterLabel={(
                                            <Icon sx={{ fontSize: '18px', color: 'orange' }}>warning</Icon>
                                        )}
                                        checked={tempDisplayModes.brute}
                                        onToggle={event => onChangeDisplayMode({ brute: event.target.checked })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        label={i18n.min}
                                        labelStyle={{ fontWeight: 'normal' }}
                                        beforeLabel={(
                                            <div style={{ ...roundDivStyle, backgroundColor: 'red' }} />
                                        )}
                                        checked={tempDisplayModes.min}
                                        onToggle={event => onChangeDisplayMode({ min: event.target.checked })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        label={i18n.max}
                                        labelStyle={{ fontWeight: 'normal' }}
                                        beforeLabel={(
                                            <div style={{ ...roundDivStyle, backgroundColor: 'blue' }} />
                                        )}
                                        checked={tempDisplayModes.max}
                                        onToggle={event => onChangeDisplayMode({ max: event.target.checked })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        label={i18n.average}
                                        labelStyle={{ fontWeight: 'normal' }}
                                        beforeLabel={(
                                            <div style={{ ...roundDivStyle, backgroundColor: 'green' }} />
                                        )}
                                        checked={tempDisplayModes.average}
                                        onToggle={event => onChangeDisplayMode({ average: event.target.checked })}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: '1rem' }}>
                                <ButtonMUI
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    onClick={() => checkConfirmation(tempDisplayModes.brute, setIsOpenDisplayMode, onValidateDisplayMode)}
                                >
                                    {i18n.toLoad}
                                </ButtonMUI>
                            </Grid>
                        </Grid>
                    </Popover>
                    <TooManyDataDialog
                        isOpen={isOpenDisplayMode}
                        onClose={() => setIsOpenDisplayMode(false)}
                        onValidate={onValidateDisplayMode}
                    />
                </>
            )}
            {!!(dataTypes.length && !inprogress) && (
                <>
                    <Popover
                        open={!!dataTypeEl}
                        anchorEl={dataTypeEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{ borderRadius: MEDIUM_RADIUS, maxHeight: { xl: '100%', lg: MAX_POPOVER_HEIGHT, xs: 'auto' } }}
                        onClose={() => setDataTypeEl(undefined)}
                    >
                        <Grid container sx={{ width: '300px', padding: '0 1rem 1rem' }} spacing={1}>
                            {keys(groupedDataTypes).map(key => (
                                <Grid item xs={12}>
                                    <StyledFieldSet style={{ padding: '1rem' }}>
                                        <StyledLegend>{getI18nOrLabel(key)}</StyledLegend>
                                        <Grid container spacing={1}>
                                            {orderBy(groupedDataTypes[key], 'label').map(dt => (
                                                <Grid item xs={12}>
                                                    <SimpleCheckbox
                                                        label={dt.label}
                                                        labelStyle={{ fontWeight: 'normal' }}
                                                        checked={tempDataTypes.some(tdt => tdt.typeId === dt.typeId)}
                                                        onToggle={() => tempDataTypes.some(tdt => tdt.typeId === dt.typeId) ? setTempDataTypes(prev => prev.filter(p => p.typeId !== dt.typeId)) : setTempDataTypes(prev => [...prev, dt])}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </StyledFieldSet>
                                </Grid>
                            ))}
                            <Grid item xs={12} sx={{ paddingTop: '1rem' }}>
                                <ButtonMUI
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    onClick={() => {
                                        setDataTypeEl(undefined)
                                        setDataTypes(tempDataTypes)
                                    }}
                                >
                                    {i18n.toLoad}
                                </ButtonMUI>
                            </Grid>
                        </Grid>
                    </Popover>
                </>
            )}
            <TooManyDataDialog
                isOpen={isOpenHisto}
                onClose={() => setIsOpenHisto(false)}
                onValidate={() => onChange(HISTO)}
            />
        </Grid>
    )
}

ChartTabs.propTypes = {
    time: PropTypes.number,
    onChangeTime: PropTypes.func,
    statistics: PropTypes.arrayOf(PropTypes.shape({})),
    stationType: PropTypes.string,
    setDisplayCote: PropTypes.func,
    setDiplayModes: PropTypes.func,
    inprogress: PropTypes.bool,
    dataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    setDataTypes: PropTypes.func,
}

export default ChartTabs