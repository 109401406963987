import React from 'react'
import PropTypes from 'prop-types'
import MultilineChartIcon from '@mui/icons-material/MultilineChart'
import { Box, Divider, Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { camelCase, isNil } from 'lodash'
import { ellipsisText, mainBlue, mainWhite, SMALL_RADIUS } from 'components/styled/Theme'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'

const MARGE = '0.65rem'
const FONT_SIZE = '0.9rem'

const ContentStation = ({
    feature = {},
    onClick = () => {},
    detail = false,
}) => {
    const element = document.getElementById('iconChart')
    if (!isNil(element)) {
        element.addEventListener('click', onClick)
    }

    const color = feature.color || 'white'
    const title = `[${feature.code || 'Code indisponible'}] ${feature.name}${feature.town ? ` - ${feature.town}` : ''}`

    const linkLabel = [OBSERVATORY_STATION_TYPE_NAME.catchment,
        STATION_TYPE_NAME.piezometry,
        STATION_TYPE_NAME.hydrometry,
        STATION_TYPE_NAME.pluviometry].includes(feature.station?.stationType) ? i18n.seeDetails : i18n.accessToTracking

    return (
        <Grid
            container
            sx={{
                border: `solid 3px ${color}`,
                borderRadius: SMALL_RADIUS,
                overflow: 'hidden',
                fontSize: FONT_SIZE,
            }}
        >
            <Grid container item xs={12} alignItems='center'>
                <Grid container item xs={2} alignItems='center' sx={{ height: '100%', backgroundColor: color }}>
                    <Grid item xs={12} sx={{ '& img': { height: '40px' }, textAlign: 'center', padding: MARGE }}>
                        <img src={feature.icon} alt={feature.name} height='40px' />
                    </Grid>
                </Grid>
                <Grid container item xs={10} sx={{ backgroundColor: mainWhite, borderRadius: `0 ${SMALL_RADIUS} ${SMALL_RADIUS} 0` }}>
                    <Grid container item xs={12} sx={{ padding: MARGE }}>
                        <Grid item xs={12} sx={{ fontWeight: 'bold', paddingBottom: MARGE }}>
                            {title}
                        </Grid>
                        {feature.lastMeasures.map((lm, lmIndex) => {
                            const thresholds = feature.thresholds?.filter(th => th.dataType === `${lm.dataType}`).map((threshold, i) => (
                                <Grid container key={i} item xs={12} justifyContent='space-between'>
                                    <Grid item xs={7} sx={ellipsisText}>
                                        {threshold.name ?? 'Seuil inconnu'}
                                    </Grid>
                                    <Grid container item xs={4.5} justifyContent='flex-end' sx={ellipsisText}>
                                        <Grid item>
                                            {threshold.value ? `${threshold.value.toFixed(2)} ${threshold.unit || ''}` : 'Pas de donnée'}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))

                            return (
                                <Grid container key={`TypeDeDonnee_${camelCase(lm.dataTypeLabel)}`} item xs={12}>
                                    {lmIndex !== 0 && (
                                        <Grid item xs={12} sx={{ paddingTop: MARGE }}>
                                            <Divider />
                                        </Grid>
                                    )}
                                    {!!detail && (
                                        <Grid container item xs={12} columnSpacing={1} alignItems='center' sx={{ paddingTop: MARGE, fontWeight: 700 }}>
                                            <Grid item xs={4.5} sx={ellipsisText}>
                                                {lm.dataTypeLabel}
                                            </Grid>
                                            <Grid container item xs={7.5} columnSpacing={2} justifyContent='flex-end' sx={{ textAlign: 'end' }}>
                                                <Grid item xs sx={ellipsisText}>
                                                    {(!lm.date || lm.date === 'Invalid Date') ? 'Date indisponible' : lm.date}
                                                </Grid>
                                                <Grid item xs='auto' sx={ellipsisText}>
                                                    {!isNil(lm.value) ? `${lm.value.toFixed(2)} ${lm.unit || ''}` : 'Pas de valeur'}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {!!(thresholds.length && detail) && (
                                        <Grid container item xs={12} sx={{ paddingTop: MARGE }}>
                                            {thresholds}
                                        </Grid>
                                    )}
                                </Grid>
                            )
                        })}
                        <Grid container item xs={12} justifyContent='flex-end' sx={{ paddingTop: MARGE }}>
                            <Grid container item sx={{ width: 'fit-content', paddingTop: MARGE }} justifyContent='space-between' alignItems='center'>
                                <Grid
                                    container item
                                    alignItems='center'
                                    justifyContent='center'
                                    sx={{
                                        padding: '0.15rem 0.5rem',
                                        width: 'fit-content',
                                        backgroundColor: '#fff',
                                        color: mainBlue,
                                        border: `solid 1px ${mainBlue}`,
                                        borderRadius: '50rem',
                                        cursor: 'pointer',
                                        transition: 'all .25s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: mainBlue,
                                            color: '#fff',
                                            '& svg': {
                                                color: '#fff',
                                            },
                                        },
                                    }}
                                    id='iconChart'
                                >
                                    <Grid item>
                                        <MultilineChartIcon sx={{ color: mainBlue, verticalAlign: 'middle', fontSize: '1rem', transition: 'color .2s ease-in-out' }} size='small' />
                                    </Grid>
                                    <Grid item sx={{ paddingLeft: '0.5rem' }}>
                                        <p style={{ margin: 0 }}>{linkLabel}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

ContentStation.propTypes = {
    feature: PropTypes.shape({
        name: PropTypes.string,
        desc: PropTypes.shape({}),
        marker: PropTypes.string,
    }),
    onClick: PropTypes.func,
    detail: PropTypes.bool,
}

const PopupContent = ({
    features = [],
    onClick = () => {},
}) => {
    const feature = features[0]
    const detail = [
        OBSERVATORY_STATION_TYPE_NAME.catchment,
        STATION_TYPE_NAME.piezometry,
        STATION_TYPE_NAME.hydrometry,
        STATION_TYPE_NAME.pluviometry,
    ].includes(feature.station?.stationType)

    return (
        <Box sx={{ display: 'block', height: 'auto' }}>
            <ContentStation feature={feature} onClick={onClick} detail={detail} />
        </Box>
    )
}

PopupContent.propTypes = {
    features: PropTypes.oneOfType([
        PropTypes.shape({
            name: PropTypes.string,
            desc: PropTypes.shape({}),
            icon: PropTypes.string,
        }),
        PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                desc: PropTypes.shape({}),
                icon: PropTypes.string,
            }),
        ),
    ]),
    onClick: PropTypes.func,
}

export default PopupContent