import React from 'react'
import PropTypes from 'prop-types'
import { DialogActions, Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import CloseIcon from '@mui/icons-material/Close'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from 'pages/online/components/styled/dialog'
import { mainWhite } from 'components/styled/Theme'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import PiezoChartPanel from 'pages/online/follows/components/PiezoChartPanel'
import HydroChartPanel from 'pages/online/follows/components/HydroChartPanel'
import PluvioChartPanel from 'pages/online/follows/components/PluvioChartPanel'
import ProgressBar from 'components/progress/ProgressBar'
import { ButtonMUI } from 'components/styled/Buttons'

const GraphPopup = ({
    isOpen = false,
    onClose = () => {},
    onClick = () => {},
    selectedStation = {},
}) => (
    <DialogMUI
        open={isOpen}
        maxWidth='lg'
        PaperProps={{
            sx: {
                minHeight: { xl: '75vh', lg: '85vh' },
                maxHeight: { xl: '75vh', lg: '85vh' },
            },
        }}
        onClose={onClose}
    >
        <DialogTitleMUI sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item sx={{ '& h3': { color: '#fff !important' } }}>
                <h3 style={{ margin: 0, fontWeight: '600', fontSize: '1.25rem' }}>
                    {!selectedStation ? 'Station' : `[${selectedStation.code || 'Code indisponible'}] ${selectedStation.name}${selectedStation.town ? ` - ${selectedStation.town}` : ''}`}
                </h3>
            </Grid>
            <Grid item>
                <CloseIcon
                    sx={{ color: mainWhite, cursor: 'pointer' }}
                    size='small'
                    icon='close'
                    onClick={onClose}
                />
            </Grid>
        </DialogTitleMUI>
        <DialogContentMUI>
            <Grid container sx={{ padding: '1.5rem' }}>
                {!selectedStation ? (
                    <Grid item xs={12}>
                        <ProgressBar indeterminate />
                    </Grid>
                ) : (
                    <Grid container item xs={12}>
                        {([OBSERVATORY_STATION_TYPE_NAME.catchment, STATION_TYPE_NAME.piezometry].includes(selectedStation.stationType)) && <PiezoChartPanel station={selectedStation} />}
                        {(selectedStation.stationType === STATION_TYPE_NAME.hydrometry) && <HydroChartPanel station={selectedStation} />}
                        {(selectedStation.stationType === STATION_TYPE_NAME.pluviometry) && <PluvioChartPanel station={selectedStation} />}
                    </Grid>
                )}
            </Grid>
        </DialogContentMUI>
        <DialogActions>
            <Grid container item xs={12} justifyContent='flex-end' alignItems='center'>
                <Grid item>
                    <ButtonMUI
                        variant='contained'
                        color='primary'
                        onClick={onClick}
                    >
                        {i18n.accessToTracking}
                    </ButtonMUI>
                </Grid>
            </Grid>
        </DialogActions>
    </DialogMUI>
)

GraphPopup.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    selectedStation: PropTypes.object,
}

export default GraphPopup