import moment from 'moment'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { CHART_SELECTED_TIME } from 'pages/home/constants/HomeConstants'
import { DISPLAY_COTE, MEASURE_COTE, PIEZO_TAB_DISPLAY_MODES } from 'pages/online/components/echart/ChartConstant'
import { HISTO, J365 } from 'pages/online/components/echart/ChartFollowContants'
import { getBarWidth, getSubstractTime, setYOptionsPiezo, yAutomaticScaleValues } from 'pages/online/components/echart/EChartUtils'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useAbortController from 'utils/customHook/useAbortController'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import useApplicationSetting, { intParser } from 'utils/customHook/useApplicationSetting'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import FollowAction from '../actions/FollowAction'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { concat, groupBy, isNil, keys, maxBy, round, uniq, uniqBy } from 'lodash'
import { Grid } from '@mui/material'
import ChartTabs from 'pages/online/components/echart/ChartTabs'
import ProgressBar from 'components/progress/ProgressBar'
import { getDateWithHour, getDayDiff, getFullDate } from 'utils/DateUtil'
import { getEventColor, getRGBColor } from 'utils/ColorUtil'
import { chartGrey } from 'components/styled/Theme'
import { hasValue } from 'utils/NumberUtil'
import { chunkWithWords } from 'utils/StringUtil'
import MultiChart from 'pages/online/components/echart/MultiChart'
import { authorizeExport } from 'utils/HabilitationUtil'
import { PIEZO } from 'pages/home/constants/HabilitationConstants'
import DtoPiezometer from 'pages/home/dto/DtoPiezometer'
import DtoMeasureStats from '../dto/DtoMeasureStats'
import DtoEvent from '../dto/DtoEvent'
import DtoPiezometerChartOptions from '../dto/DtoPiezometerChartOptions'
import DtoPiezometryStationMeasure from 'pages/online/referencials/dto/DtoPiezometryStationMeasure'
import { renderToString } from 'react-dom/server'
import { statusIcon } from 'utils/StatusUtil'

const CHRONIC_TYPE = -1
const SAMPLE_TYPE = -2
const ALL = 'all'
const GROUPS_FUNC = {
    RAW: 'RAW',
    MAX: 'MAX',
    MIN: 'MIN',
    AVERAGE: 'AVERAGE',
}
const ROUND_VALUE = 3
const SOIL_FOOT_PIEZOMETER = 3
const DEFAULT_DISPLAY_COTE = 0
const DEFAULT_DISPLAY_MODES = {
    auto: true,
    brute: false,
    min: false,
    max: false,
    average: false,
}

const PiezometerChart = ({
    station,
    stats = [],
    stationsEvents = [],
    measures = [],
    stationThresholds = [],
    typesIdWithSeveralDataPerDay = [],
    displayCote = 0,

    piezometryOptions,

    graphicHeight = 250,
    onFullScreen = () => {},
    defaultMinDate,
    defaultMaxDate,
}) => {
    const {
        piezometryDataTypes,
    } = useSelector(store => ({
        piezometryDataTypes: store.FollowReducer.piezometryDataTypes,
    }), shallowEqual)

    const stationName = station.name
    const stationCode = station.code

    const events = stationsEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            return e.graph === '1'
        }
        return false
    })

    const getBarDataEvents = () => events.map(e => ({
        date: e.startDate || getDateWithHour(e.date, e.eventHour).valueOf(),
        value: 1,
        color: getRGBColor(getEventColor(e.eventType)),
        event: e,
    }))

    const eventsFormatted = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        gridName: i18n.events,
        dataList: getBarDataEvents(),
        type: 'bar',
        barWidth: '10px',
    }], [])

    const groupedMeasures = useMemo(() => {
        const measuresFiltered = measures.filter(m => !!m.measures.length)
        return groupBy(measuresFiltered, 'dataType')
    }, [measures])
    const groupedMeasuresKeys = useMemo(() => {
        const keysGroupedMeasures = keys(groupedMeasures)
        const chronicleTypeIds = piezometryDataTypes.filter(pdt => pdt.isPiezo).map(pdt => pdt.id)
        const chronicleKeys = keysGroupedMeasures.filter(k => k === `${CHRONIC_TYPE}` || chronicleTypeIds.includes(parseInt(k)))
        const chronicleKey = chronicleKeys.length ? [`${CHRONIC_TYPE}`] : []
        return [
            ...chronicleKey,
            ...keysGroupedMeasures.filter(k => k === `${SAMPLE_TYPE}`),
            ...keysGroupedMeasures.filter(k => k !== `${CHRONIC_TYPE}` && k !== `${SAMPLE_TYPE}` && !chronicleTypeIds.includes(parseInt(k))).sort((a, b) => parseInt(a) - parseInt(b)),
            ...keysGroupedMeasures.filter(k => k !== `${CHRONIC_TYPE}` && k !== `${SAMPLE_TYPE}` && chronicleTypeIds.includes(parseInt(k))).sort((a, b) => parseInt(a) - parseInt(b)),
        ].filter(k => !!k && k !== 'undefined')
    }, [groupedMeasures])

    const getFilteredData = (data) => data.flatMap((m, i) => {
        const diffInDays = (m?.date && data[i + 1]?.date) ? getDayDiff(data[i + 1].date, m.date) : 0
        return (m.initialPoint !== 1 && diffInDays <= 31 && !isNil(m?.date)) ? [m] : [
            { ...m, value: undefined, date: moment(m.date).subtract(1, 'second').valueOf() },
            { ...m, marker: 'emptyCircle' },
        ]
    })

    const getGroupColor = (groupFunc, defautColor) => {
        switch (groupFunc) {
            case GROUPS_FUNC.RAW:
                return defautColor
            case GROUPS_FUNC.MAX:
                return 'blue'
            case GROUPS_FUNC.MIN:
                return 'red'
            case GROUPS_FUNC.AVERAGE:
                return 'green'
            default:
                return defautColor
        }
    }

    const getGroupLabel = groupFunc => {
        switch (groupFunc) {
            case GROUPS_FUNC.RAW:
                return i18n.gross
            case GROUPS_FUNC.MAX:
                return i18n.max
            case GROUPS_FUNC.MIN:
                return i18n.min
            case GROUPS_FUNC.AVERAGE:
                return i18n.average
            default:
                return ''
        }
    }

    const measuresFormatted = useMemo(() => groupedMeasuresKeys.flatMap((key, index) => {
        const values = groupedMeasures[key] || []

        return values.map(value => {
            const stat = stats.find(h => h.typeId === value.dataType)
            const dataType = piezometryDataTypes.find(pdt => pdt.id === value.dataType) || {}
            const unit = stat?.unit ? `${stat?.unit} ` : ''

            const severalData = typesIdWithSeveralDataPerDay.find(t => t.typeId === value.dataType)

            const defaultColor = stat?.color || (dataType.isPiezo ? 'red' : chartGrey)
            const color = ((dataType.isPiezo || severalData?.typeId === CHRONIC_TYPE) && severalData?.hasSeveralDataPerDay) ? (value.groupFunc === GROUPS_FUNC.MAX ? 'blue' : (value.groupFunc === GROUPS_FUNC.MIN ? 'red' : defaultColor)) : defaultColor
            const groupColor = keys(GROUPS_FUNC).includes(value.groupFunc) ? getGroupColor(value.groupFunc, color) : color

            const defaultName = value.dataType === CHRONIC_TYPE ? i18n.depths : stat.label || ''
            const additionalName = (severalData?.hasSeveralDataPerDay || value.groupFunc !== ALL) ? `${defaultName} ${getGroupLabel(value.groupFunc)}` : defaultName
            return {
                gridIndex: dataType.isPiezo ? 1 : index + 1,
                name: additionalName,
                dataType: value.dataType,
                unit,
                color: groupColor,
                gridName: stat?.label || key,
                dataList: getFilteredData(value.measures),
                type: value.dataType === SAMPLE_TYPE ? 'bar' : 'line',
                connectNulls: false,
                barWidth: value.dataType === SAMPLE_TYPE ? getBarWidth(value.measures.length) : undefined,
            }
        })
    }), [groupedMeasures, groupedMeasuresKeys, stationName, stationCode, stats])

    const dataFormatted = [...eventsFormatted, ...measuresFormatted]

    const thresholds = stats.length ? uniqBy(stationThresholds.filter(t => groupedMeasuresKeys.includes(`${t.dataType}`)), 'id').map((t, index) => {
        const stat = stats.find(h => h.typeId === parseInt(t.dataType))
        const unit = stat?.unit ? `${stat?.unit} ` : ''
        return {
            ...t,
            unit,
            gridName: stat?.label || t.dataType,
            gridIndex: measuresFormatted.find(mf => `${mf.dataType}` === t.dataType)?.gridIndex || (index + 1),
        }
    }) : []

    const eventGrids = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        yOptions: {
            type: 'value',
            nameRotate: 0,
            nameGap: 20,
            minInterval: 1,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        xOptions: {
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        },
        gridOptions: {
            top: 40,
            left: '100px',
            height: 40,
        },
    }], [])

    const getPiezometerOption = type => {
        if (type == 0) {
            return piezometryOptions?.find(opt => !opt.dataType || parseInt(opt.dataType) <= 0) || {}
        }
        return piezometryOptions?.find(opt => type == opt.dataType) || {}
    }

    const calculateLastLandmarks = () => {
        const lastLandmark = maxBy(station.link_landmarks, 'startDate')
        const lastRefAlti = lastLandmark ? station.link_altimetrySystems?.find(alt => alt.natureCode === lastLandmark.altimetrySystemNature && alt.startDate === lastLandmark.altimetrySystemDate) : null

        const groundRefALtis = station.link_altimetrySystems?.filter(alt => alt.natureCode == SOIL_FOOT_PIEZOMETER)
        const groundRefAlti = groundRefALtis?.length ? maxBy(groundRefALtis, 'startDate') : null
        return { lastLandmark: lastLandmark && lastRefAlti ? lastLandmark.height + lastRefAlti.altitude : null, groundRefAlti: groundRefAlti && groundRefAlti.altitude }
    }

    const measuresGrids = useMemo(() => measuresFormatted.length ? groupedMeasuresKeys.filter(g => parseInt(g) === CHRONIC_TYPE || !piezometryDataTypes.find(pdt => pdt.id === parseInt(g))?.isPiezo).map((key, index) => {
        const dataType = groupedMeasures[key]?.[0]?.dataType
        const stat = stats.find(h => h.typeId === dataType)
        const unit = stat?.unit ? `[${stat?.unit}] ` : ''

        const option = getPiezometerOption(dataType)
        const { lastLandmark, groundRefAlti } = calculateLastLandmarks()
        return {
            gridIndex: index + 1,
            name: `${key === `${CHRONIC_TYPE}` ? i18n.depths : stat?.label || key} ${unit}`,
            dataType,
            gridOptions: {
                left: '100px',
                top: 35,
                height: graphicHeight,
            },
            yOptions: {
                inverse: (dataType === CHRONIC_TYPE && displayCote !== MEASURE_COTE.NGF),
                calculateY: true,
                ...setYOptionsPiezo(
                    option,
                    dataType,
                    yAutomaticScaleValues(concat(
                        measuresFormatted.filter(m => m.gridIndex === (index + 1)).flatMap(m => m.dataList.map(d => d.value)),
                        thresholds.filter(m => m.gridIndex === (index + 1)).map(({ value }) => value),
                    )),
                    (dataType === CHRONIC_TYPE ? 2 : 0),
                    lastLandmark,
                    groundRefAlti,
                ),
            },
        }
    }) : [], [graphicHeight, groupedMeasures, groupedMeasuresKeys, measuresFormatted, stats, piezometryOptions])

    const grids = [...eventGrids, ...measuresGrids]

    const formatTooltip = params => {
        if (keys(params[0].value[2]?.event)?.length) {
            return getFullDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [,, { event }] }) => {
                return `<br/>${marker} ${seriesName}: ${event.comment ? (chunkWithWords(event.comment, 40).replaceAll('\n', '<br />')) : ''}<br />
                ${hasValue(event.ns) ? `${i18n.staticLevelMeasure} : ${event.ns}m<br />` : ''}
                ${hasValue(event.nc) ? `${i18n.sensorInstantLevel} : ${event.nc}m<br />` : ''}`
            }).join('')
        }
        return getFullDate(params[0].axisValue) + params.filter(({ value: [, result] }) => !isNil(result)).map(({ marker, seriesName, value: [, result, statusObj], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, ROUND_VALUE)} ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`).join('')
    }

    return (
        <MultiChart
            data={dataFormatted}
            grids={grids}
            stationsEvents={stationsEvents}
            thresholds={thresholds}
            roundValue={ROUND_VALUE}
            headerHeight={20}
            footerHeight={70}
            exportName={stationName || stationCode || ''}
            withDataZoom
            withFullScreen
            withOtherLegend
            withYZoom
            withToolLine
            withToolMarker
            onFullScreen={onFullScreen}
            defaultDisplayMarker={false}
            tooltipFormatter={formatTooltip}
            defaultMinDate={defaultMinDate}
            defaultMaxDate={defaultMaxDate}
            withExport={authorizeExport(PIEZO)}
        />
    )
}

PiezometerChart.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            code: PropTypes.string,
        }),
    ]),
    stats: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    piezometryOptions: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerChartOptions)),
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    typesIdWithSeveralDataPerDay: PropTypes.arrayOf(PropTypes.shape({
        hasSeveralDataPerDay: PropTypes.bool,
        typeId: PropTypes.number,
    })),
    displayCote: PropTypes.number,
    stationThresholds: PropTypes.arrayOf(PropTypes.shape({})),
    graphicHeight: PropTypes.number,
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
}

const PiezoChartPanel = ({
    station,
    stationType,
    graphicHeight = 250,
}) => {
    const idMinJournalier = useApplicationSetting('ID_MIN_JOURNALIER', intParser)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const lastSelectedTime = useAccountSetting(CHART_SELECTED_TIME, v => v === HISTO ? v : (v ? parseInt(v) : J365)) || J365
    const getDefaultMinDate = () => lastSelectedTime === HISTO ? undefined : getSubstractTime(lastSelectedTime)
    const getDefaultMaxDate = () => lastSelectedTime === HISTO ? moment().valueOf() : undefined

    const [time, setTime] = useState(lastSelectedTime || J365)
    const [minDate, setMinDate] = useState(getDefaultMinDate())
    const [maxDate, setMaxDate] = useState(getDefaultMaxDate())
    const [displayCote, setDisplayCote] = useLocalStorage(DISPLAY_COTE, DEFAULT_DISPLAY_COTE)
    const [displayModes, setDiplayModes] = useLocalStorage(PIEZO_TAB_DISPLAY_MODES, DEFAULT_DISPLAY_MODES)
    const [measures, setMeasures] = useState([])
    const [fullScreen, setFullScreen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)

    const [piezoThresholds, setPiezoThresholds] = useState([])
    const [piezoMeasuresStats, setPiezoMeasuresStats] = useState([])
    const [stationEvents, setStationEvents] = useState([])
    const [piezometryOptions, setPiezometryOptions] = useState()

    const [typesIdWithSeveralDataPerDay, setTypesIdWithSeveralDataPerDay] = useState([])
    const [dataPerDayIsLoaded, setDataPerDayIsLoaded] = useState(false)
    const [dataPerDayProgress, setDataPerDayProgress] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        initController()

        return () => {
            controllerRef.current.abort()

            setTime(J365)
            setMinDate(moment().subtract(J365, 'days').startOf('day').valueOf())
            setMaxDate(undefined)
            setMeasures([])
            setFullScreen(false)
            setDataLoaded(false)

            setPiezoThresholds([])
            setPiezoMeasuresStats([])
            setStationEvents([])
        }
    }, [])

    const { progress, isLoaded } = useProgressDispatch(() => [
        dispatch(FollowAction.fetchPiezometerThresholds(station.id)).then(({ payload }) => setPiezoThresholds((payload || []))),
        dispatch(FollowAction.fetchPiezoMeasuresStats(station.id)).then(({ payload }) => setPiezoMeasuresStats((payload || []))),
        dispatch(FollowAction.fetchStationsEvents({ type: STATION_TYPE_NAME.piezometry, ids: [station.id] })).then(({ payload }) => setStationEvents((payload || []))),
        dispatch(FollowAction.fetchPiezometerChartOptions(station.id)).then(({ payload }) => setPiezometryOptions(payload)),
    ], [])

    const dataTypesId = useMemo(() => uniq(piezoMeasuresStats.map(stat => stat.typeId)), [piezoMeasuresStats])

    useEffect(() => {
        if (isLoaded) {
            dispatch(FollowAction.hasSeveralDataPerDay({
                dataTypesId,
                stationId: station.id,
                progressCallback: setDataPerDayProgress,
            })).then(({ payload }) => {
                setTypesIdWithSeveralDataPerDay(payload)
                setDataPerDayIsLoaded(true)
            })
        }
    }, [station.id, isLoaded, dataTypesId])

    useEffect(() => {
        if (isLoaded && dataPerDayIsLoaded) {
            setMeasures([])
            setDataLoaded(false)
            const defaultGroupMode = time !== HISTO ? ALL : GROUPS_FUNC.MAX
            const inputs = dataTypesId.flatMap(typeId => {
                const isChronicle = typeId === CHRONIC_TYPE
                const isMinJournalier = (!isNil(idMinJournalier) && typeId === idMinJournalier)
                const hasSeveralDataPerDay = typesIdWithSeveralDataPerDay.find(t => t.typeId === typeId)?.hasSeveralDataPerDay
                const defaultInput = {
                    stationId: station.id,
                    dataType: typeId,
                    chartMode: true,
                    startDate: minDate,
                    endDate: maxDate,
                    validOnly: isChronicle,
                    displayCote: (isChronicle || isMinJournalier) ? (displayCote !== DEFAULT_DISPLAY_COTE ? displayCote : MEASURE_COTE.DEPTH) : DEFAULT_DISPLAY_COTE,
                }
                const piezoModes = uniq([
                    displayModes.brute ? GROUPS_FUNC.RAW : (displayModes.auto ? defaultGroupMode : null),
                    displayModes.max ? GROUPS_FUNC.MAX : null,
                    displayModes.min ? GROUPS_FUNC.MIN : null,
                    displayModes.average ? GROUPS_FUNC.AVERAGE : null,
                ].filter(m => !!m))

                if (isChronicle && piezoModes?.length) {
                    return piezoModes.map(pm => ({
                        ...defaultInput,
                        groupFunc: pm,
                    }))
                } else if (isChronicle && hasSeveralDataPerDay) {
                    return (stationType === OBSERVATORY_STATION_TYPE_NAME.catchment) ? [{
                        ...defaultInput,
                        groupFunc: GROUPS_FUNC.MAX,
                    }, {
                        ...defaultInput,
                        groupFunc: GROUPS_FUNC.MIN,
                    }] : [{
                        ...defaultInput,
                        groupFunc: GROUPS_FUNC.MAX,
                    }]
                }
                return [{
                    ...defaultInput,
                    groupFunc: typeId === SAMPLE_TYPE ? 'SUM' : defaultGroupMode,
                }]
            })
            dispatch(FollowAction.loadPiezoChartMeasures(inputs, () => {}, controllerRef.current.signal)).then(data => {
                setMeasures(prev => [...prev, ...data])
                setDataLoaded(true)
            })
        }
    }, [isLoaded, dataPerDayIsLoaded, time, displayCote, displayModes])

    const fullScreenStyle = fullScreen ? {
        position: 'fixed',
        top: '116px',
        left: 0,
        width: '100%',
        height: 'calc(100vh - 116px)',
        zIndex: 9,
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    const chartHeight = graphicHeight || window.innerHeight / 5

    return (isLoaded && dataPerDayIsLoaded) ? (
        <Grid container sx={fullScreenStyle}>
            <Grid item xs={12}>
                <ChartTabs
                    time={time}
                    onChangeTime={newTime => {
                        setMinDate(newTime.minDate)
                        setMaxDate(newTime.maxDate)
                        setTime(newTime.time)
                    }}
                    statistics={piezoMeasuresStats}
                    stationType={stationType}
                    setDisplayCote={setDisplayCote}
                    setDiplayModes={setDiplayModes}
                    inprogress={!dataLoaded}
                />
            </Grid>
            <Grid item xs={12}>
                {!dataLoaded ? (
                    <ProgressBar indeterminate />
                ) : (
                    <PiezometerChart
                        station={station}
                        stats={piezoMeasuresStats}
                        stationsEvents={stationEvents}
                        measures={measures}
                        stationThresholds={piezoThresholds}
                        typesIdWithSeveralDataPerDay={typesIdWithSeveralDataPerDay}
                        displayCote={displayCote}

                        piezometryOptions={piezometryOptions}

                        onFullScreen={() => setFullScreen(prevFullScreen => !prevFullScreen)}
                        defaultMinDate={minDate}
                        defaultMaxDate={moment().valueOf()}
                        graphicHeight={chartHeight}
                    />
                )}
            </Grid>
        </Grid>
    ) : <ProgressBar progress={(progress + dataPerDayProgress) / 2} />
}

PiezoChartPanel.propTypes = {
    station: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    stationType: PropTypes.string,
    idsHydroDataType: PropTypes.arrayOf(PropTypes.number),
    idsPluvioDataType: PropTypes.arrayOf(PropTypes.number),
    graphicHeight: PropTypes.number,
    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    instIds: PropTypes.arrayOf(PropTypes.number),
    withChroniclesFollowUp: PropTypes.bool,
    setInstallationTypesChart: PropTypes.func,
    setDataTypesChart: PropTypes.func,
    showTitle: PropTypes.bool,
}

export default PiezoChartPanel