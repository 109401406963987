import React, { useEffect, useState } from 'react'
import { Divider, Drawer, FilledInput, Grid, Icon, IconButton, InputAdornment, List, ListItem, ListItemText, filledInputClasses, useMediaQuery } from '@mui/material'
import aquasysLogo from 'assets/pictures/AQUASYS-logo-sm.png'
import { mainBlack } from 'components/styled/Theme'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { mainGrey, mainWhite } from 'components/styled/Theme'
import packageJson from '../../../package.json'
import HomeAction from 'pages/home/actions/HomeAction'
import { aquasysPath } from 'conf/SieauConstants'
import { getUrlScreen } from 'utils/mapUtils/UrlUtils'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_DISTRIBUTION_MODULE, H_HYDRO_MODULE, H_PIEZO_MODULE, H_PLUVIO_MODULE, H_PRODUCTION_MODULE, H_QUALITO_MODULE, H_RESOURCE_MODULE } from 'pages/home/constants/AccessRulesConstants'
import { cmsPath } from 'conf/basepath'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import CmsAction from 'pages/cms/actions/CmsAction'

const RESEARCH = -1
const HOME = 0
const RESOURCES = 1
const UNITS = 2
const FOLLOWS = 3
const DOCUMENTS = 4
const CONTACT = 5
const ACCOUNT = 6
const FAQ = 7
const UDI = 8

const Option = ({
    active = false,
    title = '',
    onClick = () => {},
    xs,
}) => (
    <Grid
        item
        xs={xs}
        className='clickable'
        sx={{
            backgroundColor: active && mainBlack,
            padding: '1rem 0',
            color: active ? mainWhite : mainBlack,
            fontWeight: 'bold',
            textAlign: 'center',
            textTransform: 'uppercase',
            borderRadius: '50rem',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
                backgroundColor: mainBlack,
                color: mainWhite,
            },
        }}
        onClick={onClick}
    >
        {title}
    </Grid>
)

Option.propTypes = {
    active: PropTypes.bool,
    title: PropTypes.string,
    onClick: PropTypes.func,
    xs: PropTypes.number,
}

const SearchSelect = ({
    onSearch = () => {},
}) => {
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState('')

    const search = () => {
        onSearch()
        setSearchValue('')
        dispatch(push(`/research?searchValue=${searchValue}`))
    }

    return (
        <FilledInput
            id='search_input'
            type='text'
            hiddenLabel
            placeholder={i18n.search}
            disableUnderline
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    search()
                }
            }}
            endAdornment={
                <InputAdornment position='end'>
                    <IconButton
                        aria-label='search'
                        onClick={() => {
                            search()
                        }}
                        edge='end'
                    >
                        <Icon>search</Icon>
                    </IconButton>
                </InputAdornment>
            }
            sx={{
                [`&.${filledInputClasses.root}`]: {
                    height: '36px',
                },
            }}
        />
    )
}

SearchSelect.propTypes = {
    onSearch: PropTypes.func,
}


const selectedStyle = { cursor: 'pointer', textTransform: 'uppercase', justifyContent: 'center', textAlign: 'center', backgroundColor: mainBlack, color: 'white' }
const notSelectedStyle = { cursor: 'pointer', textTransform: 'uppercase', justifyContent: 'center', textAlign: 'center', backgroundColor: mainGrey, color: mainBlack }

const Menu = () => {
    const {
        accountHabilitations,
        cmsTitle,
        cmsCategories,
    } = useSelector(store => ({
        accountHabilitations: store.AccountReducer.accountHabilitations,
        cmsTitle: store.CmsReducer.cmsTitle,
        cmsCategories: store.CmsReducer.cmsCategories,
    }), shallowEqual)

    const cmsFAQParam = useApplicationSetting('categoriesCmsFaq', setting => setting ? parseInt(setting) : undefined)

    const [openBurger, setOpenBurger] = useState(false)

    const lgMatches = useMediaQuery((t) => t.breakpoints.up('lg'))

    const dispatch = useDispatch()

    const url = getUrlScreen().split('/')[1]

    useEffect(() => {
        dispatch(CmsAction.fetchCMSCategories())
        dispatch(HomeAction.setLoadingData(false))
    }, [url])

    const categFaqName = cmsCategories.find(categ => categ.id === cmsFAQParam)?.title || i18n.forum

    const getMenuByUrl = () => {
        switch (url) {
            case 'home':
                return HOME
            case 'resources':
                return RESOURCES
            case 'productions':
                return UNITS
            case 'follows':
                return FOLLOWS
            case 'documents':
                return DOCUMENTS
            case 'contact':
                return CONTACT
            case 'account':
                return ACCOUNT
            case 'forum':
                return FAQ
            case 'distributions':
                return UDI
            default:
                return RESEARCH
        }
    }

    const onClickButton = (path) => {
        setOpenBurger(false)
        dispatch(push(path))
    }

    const checkHabs = (hab) => !accountHabilitations?.length || componentHasHabilitations(hab)

    const homeHref = cmsTitle?.document?.[0] ? (cmsTitle?.link || undefined) : aquasysPath
    const homeImage = cmsTitle?.document?.[0] ? `${cmsPath}${cmsTitle?.document?.[0]?.name}` : aquasysLogo
    const hasTitle = !!cmsTitle?.title?.length

    return (
        <Grid container>
            {hasTitle && (
                <Grid
                    container item xs={12}
                    alignItems='center'
                    justifyContent='flex-start'
                    sx={{
                        backgroundColor: mainWhite,
                        color: mainBlack,
                        position: 'fixed',
                        top: '0',
                        height: '30px',
                        zIndex: '1000',
                    }}
                >
                    <Grid item>
                        <h1 style={{ margin: '0 0 0 0.5rem ', fontSize: '20px', fontWeight: '600' }}>{cmsTitle.title}</h1>
                    </Grid>
                </Grid>
            )}
            <Grid
                container item xs={12}
                alignItems='center'
                justifyContent='space-between'
                sx={{
                    backgroundColor: mainWhite,
                    position: 'fixed',
                    top: hasTitle ? '30px' : 0,
                    height: '66px',
                    zIndex: '1000',
                }}
            >
                <Grid item xs={lgMatches ? 1.25 : 3}>
                    <a href={homeHref} target='_blank'>
                        <img src={homeImage} alt='home_logo' style={{ marginLeft: '10px', maxHeight: '40px', maxWidth: '100%', width: 'maxContent', height: '100%' }} />
                    </a>
                </Grid>
                {lgMatches ? (
                    <>
                        <Grid container item xs={9} justifyContent='space-between'>
                            <Option
                                active={getMenuByUrl() === HOME}
                                title={i18n.home}
                                xs={1}
                                onClick={() => onClickButton('/home')}
                            />
                            {checkHabs(H_RESOURCE_MODULE) && (
                                <Option
                                    active={getMenuByUrl() === RESOURCES}
                                    title={i18n.resources}
                                    xs={1.5}
                                    onClick={() => onClickButton('/resources')}
                                />
                            )}
                            {checkHabs(H_PRODUCTION_MODULE) && (
                                <Option
                                    active={getMenuByUrl() === UNITS}
                                    title={i18n.productionUnit}
                                    xs={1.5}
                                    onClick={() => onClickButton('/productions')}
                                />
                            )}
                            {checkHabs(H_DISTRIBUTION_MODULE) && (
                                <Option
                                    active={getMenuByUrl() === UDI}
                                    title={i18n.distributionUnit}
                                    xs={1.5}
                                    onClick={() => onClickButton('/distributions')}
                                />
                            )}
                            {(checkHabs(H_PIEZO_MODULE) || checkHabs(H_HYDRO_MODULE) || checkHabs(H_PLUVIO_MODULE) || checkHabs(H_QUALITO_MODULE)) && (
                                <Option
                                    active={getMenuByUrl() === FOLLOWS}
                                    title={i18n.follows}
                                    xs={1}
                                    onClick={() => onClickButton('/follows')}
                                />
                            )}
                            <Option
                                active={getMenuByUrl() === DOCUMENTS}
                                title={i18n.documents}
                                xs={1.25}
                                onClick={() => onClickButton('/documents')}
                            />
                            {!!cmsFAQParam && (
                                <Option
                                    active={getMenuByUrl() === FAQ}
                                    title={categFaqName}
                                    xs={0.75}
                                    onClick={() => onClickButton('/forum')}
                                />
                            )}
                            <Option
                                active={getMenuByUrl() === CONTACT}
                                title={i18n.contact}
                                xs={1.25}
                                onClick={() => onClickButton('/contact')}
                            />
                            <Grid container item xs={2} sx={{ padding: '0 1.5rem' }} alignItems='center'>
                                <Grid item>
                                    {getMenuByUrl() !== RESEARCH && (<SearchSelect />)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={6} container justifyContent='center'>
                        <Icon sx={{ fontSize: '35px' }} className='clickable' onClick={() => setOpenBurger(true)}>menu</Icon>
                    </Grid>
                )}
                <Grid container item xs={lgMatches ? 1 : 3} justifyContent='flex-end'>
                    <Grid item>
                        <Icon
                            className='clickable'
                            sx={{ marginRight: '10px', fontSize: !lgMatches ? '30px' : '40px' }}
                            onClick={() => {
                                dispatch(push('/account'))
                            }}
                        >
                            person
                        </Icon>
                    </Grid>
                    <Grid item sx={{ position: 'relative' }}>
                        <Icon
                            className='clickable'
                            sx={{ marginRight: '10px', fontSize: !lgMatches ? '30px' : '40px' }}
                            onClick={() => dispatch(HomeAction.logout())}
                        >
                            power_settings_new
                        </Icon>
                        <span
                            style={{
                                fontSize: '9px',
                                textAlign: 'center',
                                right: '5px',
                                position: 'absolute',
                                bottom: '-7px',
                                width: '100%',
                            }}
                        >v{packageJson.version}</span>
                    </Grid>
                </Grid>
                <Drawer
                    anchor='top'
                    open={openBurger}
                    onClose={() => setOpenBurger(false)}
                    sx={{ zIndex: '2000' }}
                >
                    <List disablePadding>
                        <Grid
                            container
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{
                                backgroundColor: mainGrey,
                                height: 66,
                            }}
                        >
                            <Grid item xs={3}>
                                <a href={aquasysPath} target='_blank'>
                                    <img src={aquasysLogo} alt='aquasys_logo' width={40} height={40} style={{ marginLeft: '10px' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6} container justifyContent='center'>
                                <Icon className='clickable' sx={{ fontSize: '35px' }} onClick={() => setOpenBurger(false)}>menu</Icon>
                            </Grid>
                            <Grid container item xs={3} justifyContent='flex-end'>
                                <Grid item>
                                    <Icon
                                        className='clickable'
                                        sx={{ marginRight: '10px', fontSize: !lgMatches ? '30px' : '40px' }}
                                        onClick={() => onClickButton('/account')}
                                    >
                                            person
                                    </Icon>
                                </Grid>
                                <Grid item sx={{ position: 'relative' }}>
                                    <Icon
                                        className='clickable'
                                        sx={{ marginRight: '10px', fontSize: !lgMatches ? '30px' : '40px' }}
                                        onClick={() => {
                                            setOpenBurger(false)
                                            dispatch(HomeAction.logout())
                                        }}
                                    >
                                            power_settings_new
                                    </Icon>
                                    <span
                                        style={{
                                            fontSize: '9px',
                                            textAlign: 'center',
                                            right: '5px',
                                            position: 'absolute',
                                            bottom: '-7px',
                                            width: '100%',
                                        }}
                                    >v{packageJson.version}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                        <ListItem style={getMenuByUrl() === HOME ? selectedStyle : notSelectedStyle } onClick={() => onClickButton('/home')}>
                            <ListItemText primary={i18n.home} />
                        </ListItem>
                        <Divider />
                        {checkHabs(H_RESOURCE_MODULE) && (
                            <>
                                <ListItem style={getMenuByUrl() === RESOURCES ? selectedStyle : notSelectedStyle } onClick={() => onClickButton('/resources')}>
                                    <ListItemText primary={i18n.resources} />
                                </ListItem>
                                <Divider />
                            </>
                        )}
                        {checkHabs(H_PRODUCTION_MODULE) && (
                            <>
                                <ListItem style={getMenuByUrl() === UNITS ? selectedStyle : notSelectedStyle } onClick={() => onClickButton('/productions')}>
                                    <ListItemText primary={i18n.productionUnit} />
                                </ListItem>
                                <Divider />
                            </>
                        )}
                        {checkHabs(H_DISTRIBUTION_MODULE) && (
                            <>
                                <ListItem style={getMenuByUrl() === UDI ? selectedStyle : notSelectedStyle } onClick={() => onClickButton('/distributions')}>
                                    <ListItemText primary={i18n.distributionUnit} />
                                </ListItem>
                                <Divider />
                            </>
                        )}
                        {(checkHabs(H_PIEZO_MODULE) || checkHabs(H_HYDRO_MODULE) || checkHabs(H_PLUVIO_MODULE) || checkHabs(H_QUALITO_MODULE)) && (
                            <>
                                <ListItem style={getMenuByUrl() === FOLLOWS ? selectedStyle : notSelectedStyle } onClick={() => onClickButton('/follows')}>
                                    <ListItemText primary={i18n.follows} />
                                </ListItem>
                                <Divider />
                            </>
                        )}
                        <ListItem style={getMenuByUrl() === DOCUMENTS ? selectedStyle : notSelectedStyle } onClick={() => onClickButton('/documents')}>
                            <ListItemText primary={i18n.documents} />
                        </ListItem>
                        <Divider />
                        {!!cmsFAQParam && (
                            <>
                                <ListItem style={getMenuByUrl() === FAQ ? selectedStyle : notSelectedStyle } onClick={() => onClickButton('/faq')}>
                                    <ListItemText primary={categFaqName} />
                                </ListItem>
                                <Divider />
                            </>
                        )}
                        <ListItem style={getMenuByUrl() === CONTACT ? selectedStyle : notSelectedStyle } onClick={() => onClickButton('/contact')}>
                            <ListItemText primary={i18n.contact} />
                        </ListItem>
                        <Divider />
                    </List>
                </Drawer>
            </Grid>
        </Grid>
    )
}

export default Menu
