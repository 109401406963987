/* eslint-disable consistent-return */
import { Grid, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { mainBlack, mainGrey, SMALL_RADIUS } from 'components/styled/Theme'
import Option from 'components/Option'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import HomeAction from 'pages/home/actions/HomeAction'
import FollowAction from './actions/FollowAction'
import LittleMap from '../components/LittleMap'
import EventsCard from '../components/cards/EventsCard'
import { camelCase, isNil, isUndefined, keys, sortBy, uniq, uniqBy } from 'lodash'
import { filterObsLinkedStations, getMarkerByStationType, getStationTypeCodeFromType } from 'utils/StationUtils'
import useStateProgress from 'utils/customHook/useStateProgress'
import ProgressBar from 'components/progress/ProgressBar'
import { getSandreLabel } from 'utils/StringUtil'
import { SANDRE } from '../referencials/constants/ReferencialConstants'
import { OPERATOR_TYPE_NAME, OWNER_TYPE_NAME } from 'pages/home/constants/HomeConstants'
import { hasValue } from 'utils/NumberUtil'
import { HomeActionConstant } from 'pages/home/reducers/HomeReducer'
import { getDate } from 'utils/DateUtil'
import { getTextColorByBackground } from 'utils/ColorUtil'
import { yAutomaticScaleValues } from '../components/echart/EChartUtils'
import SituationHydrobioPanel from '../components/echart/SituationHydrobioPanel'
import SimpleMultiAutocomplete from 'components/SimpleMultiAutocomplete'
import DtoQualityThreshold from './dto/DtoQualityThreshold'
import useAbortController from 'utils/customHook/useAbortController'
import useBoolean from 'utils/customHook/useBoolean'
import { calculateThresholdResult } from 'utils/AnalyseUtils'
import { ParameterGraph } from '../../../quality/components/ParameterGraph'
import { push } from 'connected-react-router'
import ResourcesAction from '../resources/actions/ResourcesAction'
import SmallPicturePanel from '../components/SmallPicturePanel'
import FilePanel from '../components/FilePanel'
import DtoObservatoryFollowResult from './dto/DtoObservatoryFollowResult'
import DtoAssociatedStation from 'pages/home/dto/DtoAssociatedStation'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_HYDRO_MODULE, H_PIEZO_MODULE, H_PLUVIO_MODULE, H_PRODUCTION_MODULE, H_QUALITO_MODULE, H_RESOURCE_MODULE } from 'pages/home/constants/AccessRulesConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import InstallationLinkedProduction from './components/InstallationLinkedProduction'
import DtoAnalysisLight from './dto/DtoAnalysisLight'
import PiezoChartPanel from './components/PiezoChartPanel'
import HydroChartPanel from './components/HydroChartPanel'
import PluvioChartPanel from './components/PluvioChartPanel'

const ParameterPanel = ({
    qualitometer,
    thresholds = [],
    additionalData = [],

    graphOptions = {},
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.FollowReducer.parameters,
    }), shallowEqual)

    const dispatch = useDispatch()

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const defaultDraphOptions = useMemo(() => ({
        regroupAxis: true,
        ...graphOptions,
    }), [graphOptions])

    const [analysis, setAnalysis] = useState([])
    const [displayStatistics, setDisplayStatistics] = useState(false)
    const [displayAverages, setDisplayAverages] = useState(false)
    const [nbPointMovingAverage, setNbPointMovingAverage] = useState(1)
    const [displayHype, setDisplayHype] = useState(false)
    const [parameterSelected, setParameterSelected] = useState()

    const {
        value: isLoaded,
        setTrue: loaded,
        setFalse: notLoaded,
    } = useBoolean(false)

    useEffect(() => {
        if (isUndefined(qualitometer)) {
            return
        }

        notLoaded()
        setAnalysis([])
        initController()

        const analysisFilter = {
            lightMode: true,
            dataLoaded: true,
            displayAdvancedStatistics: false,
        }

        dispatch(FollowAction.getAnalysis(analysisFilter, [qualitometer.id], loaded)).then(list => setAnalysis(list.map(a => new DtoAnalysisLight(a))))

        return () => {
            controllerRef.current.abort()
            setDisplayStatistics(false)
            setDisplayAverages(false)
            setNbPointMovingAverage(1)
            setDisplayHype(false)
        }
    }, [parameterSelected, qualitometer])

    const parametersCodesByAnalysis = useMemo(() => uniq(analysis.map(a => a.parameter)), [analysis])
    const parametersFiltered = useMemo(() => sortBy(parameters.filter(p => parametersCodesByAnalysis.includes(p.code)), 'name'), [parameters, parametersCodesByAnalysis])
    const analysisFiltered = useMemo(() => analysis.filter(a => a.parameter === parameterSelected), [analysis, parameterSelected])

    const formattedAnalysis = useMemo(() => analysisFiltered.map(a => ({
        ...a,
        ...calculateThresholdResult(a, thresholds),
    })), [analysisFiltered, thresholds])

    const allValues = formattedAnalysis.map(a => a.result)
    const { max: maxY, min: minY } = yAutomaticScaleValues(allValues)

    return isLoaded ? (
        <Grid container item xs={12} sx={{ padding: '0 0.5rem' }}>
            {!!parametersFiltered.length && (
                <Grid item xs={4}>
                    <SimpleMultiAutocomplete
                        value={parameterSelected}
                        onChange={setParameterSelected}
                        options={parametersFiltered}
                        keyValue='code'
                        label={i18n.parameter}
                        disablePortal={false}
                    />
                </Grid>
            )}
            {!!analysisFiltered.length && (
                <Grid item xs={12}>
                    <ParameterGraph
                        analysis={analysisFiltered}
                        additionalData={additionalData}
                        thresholds={thresholds}

                        graphOptions={{
                            ...defaultDraphOptions,
                            maxY: maxY || 0,
                            minY: minY < 0 ? minY : 0,
                            displayStatistics,
                            displayAverages,
                            nbPointMovingAverage,
                            displayHype,
                        }}

                        componentHeight={(window.innerHeight * 0.365)}
                    />
                </Grid>
            )}
        </Grid>
    ) : <ProgressBar indeterminate />
}

ParameterPanel.propTypes = {
    qualitometer: PropTypes.number,
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    additionalData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        unit: PropTypes.string,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.number,
            value: PropTypes.number,
        })),
    })),

    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        displayStatistics: PropTypes.bool,
        displayAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,
        displayHype: PropTypes.bool,
    }),
}

const FollowEvents = ({
    station = {},
    stationType,
    piezoObsResults = [],
    hydroObsResults = [],
    pluvioObsResults = [],
    qualitoObsResults = [],
}) => {
    const getEventTypeName = (typeName) => {
        switch (typeName) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometer:
            case STATION_TYPE_NAME.piezometry:
                return STATION_TYPE_NAME.piezometer
            default:
                return typeName
        }
    }

    const eventsCodes = useMemo(() => [{ code: station.id, stationType: getEventTypeName(station.typeName) }], [station.id, station.typeName])

    return (
        <EventsCard
            height='calc(100vh - 430px)'
            codes={eventsCodes}
            stationId={station.id}
            stationType={stationType}
            showCode={false}
            piezoObsResults={piezoObsResults}
            hydroObsResults={hydroObsResults}
            pluvioObsResults={pluvioObsResults}
            qualitoObsResults={qualitoObsResults}
        />
    )
}

FollowEvents.propTypes = {
    station: PropTypes.shape({
        id: PropTypes.number,
        typeName: PropTypes.string,
    }),
    stationType: PropTypes.string,
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    hydroObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    pluvioObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    qualitoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const Follow = ({
    match: { params: { id, stationType } },
}) => {
    const {
        piezometer,
        hydrologicalStation,
        pluviometer,
        qualitometer,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,
        contributors,
        sandreCodes,
        watermasses,
        productionUnits,
        resources,

        accountUserSettingsStatus,

        accountHabilitations,

        piezometerAllThresholds,
        hydrometryThresholds,
        pluviometerAllThresholds,

        piezometryDataTypes,
        hydrometryDataTypes,
        pluviometryDataTypes,
    } = useSelector(store => ({
        piezometer: store.HomeReducer.piezometer,
        hydrologicalStation: store.HomeReducer.hydrologicalStation,
        pluviometer: store.HomeReducer.pluviometer,
        qualitometer: store.HomeReducer.qualitometer,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,
        contributors: store.HomeReducer.contributors,
        sandreCodes: store.HomeReducer.sandreCodes,
        watermasses: store.FollowReducer.watermasses,
        productionUnits: store.HomeReducer.productionUnits,
        resources: store.ResourcesReducer.resources,

        accountUserSettingsStatus: store.DataManagerReducer.account.accountUserSettingsStatus,

        accountHabilitations: store.AccountReducer.accountHabilitations,

        piezometerAllThresholds: store.FollowReducer.piezometerAllThresholds,
        hydrometryThresholds: store.FollowReducer.hydrometryThresholds,
        pluviometerAllThresholds: store.FollowReducer.pluviometerAllThresholds,

        piezometryDataTypes: store.FollowReducer.piezometryDataTypes,
        hydrometryDataTypes: store.FollowReducer.hydrometryDataTypes,
        pluviometryDataTypes: store.FollowReducer.pluviometryDataTypes,
    }), shallowEqual)

    const [simpleSheet, setSimpleSheet] = useState(true)
    const [unitLinkedStations, setUnitLinkedStation] = useState([])
    const [pictures, setPictures] = useState([])
    const [documents, setDocuments] = useState([])
    const [obsResults, setObsResults] = useState([])

    const stationId = useMemo(() => parseInt(id), [id])

    const { progress: qualityProgress, isLoaded: qualityIsLoaded } = useStateProgress([accountUserSettingsStatus])

    const dispatch = useDispatch()

    const havePiezoAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_PIEZO_MODULE), [accountHabilitations])
    const haveHydroAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_HYDRO_MODULE), [accountHabilitations])
    const havePluvioAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_PLUVIO_MODULE), [accountHabilitations])
    const haveQualitoAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_QUALITO_MODULE), [accountHabilitations])
    const haveUnitAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_PRODUCTION_MODULE), [accountHabilitations])
    const haveResourceAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_RESOURCE_MODULE), [accountHabilitations])

    useEffect(() => {
        switch (stationType) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometry:
                if (!havePiezoAccess) {
                    dispatch(HomeAction.logout())
                    dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
                }
                return
            case STATION_TYPE_NAME.hydrometry:
                if (!haveHydroAccess) {
                    dispatch(HomeAction.logout())
                    dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
                }
                return
            case STATION_TYPE_NAME.pluviometry:
                if (!havePluvioAccess) {
                    dispatch(HomeAction.logout())
                    dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
                }
                return
            case STATION_TYPE_NAME.quality:
                if (!haveQualitoAccess) {
                    dispatch(HomeAction.logout())
                    dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
                }
                return
            default:
                return
        }
    }, [haveHydroAccess, havePiezoAccess, havePluvioAccess, haveQualitoAccess, stationType])

    useEffect(() => {
        if (stationType === OBSERVATORY_STATION_TYPE_NAME.catchment || stationType === STATION_TYPE_NAME.piezometry) {
            dispatch(HomeAction.fetchPiezometer(stationId))
            if (!piezometerAllThresholds.length) {
                dispatch(FollowAction.fetchPiezometerAllThresholds())
            }
            if (!piezometryDataTypes.length) {
                dispatch(FollowAction.fetchPiezometryDataTypes())
            }
            if (!piezoObservatoryFollowResults.length) {
                dispatch(FollowAction.fetchSpecificPiezoObservatoryFollowResult([stationId])).then(result => setObsResults(result))
            } else {
                const result = [piezoObservatoryFollowResults.find(ofr => ofr.id === stationId)] || []
                setObsResults(result)
            }
        } else if (stationType === STATION_TYPE_NAME.hydrometry) {
            dispatch(HomeAction.fetchHydrologicalStation(stationId))
            if (!hydrometryThresholds.length) {
                dispatch(FollowAction.fetchHydrometricThresholds())
            }
            if (!hydrometryDataTypes.length) {
                dispatch(FollowAction.fetchHydrometryDataTypes())
            }
            if (!hydroObservatoryFollowResults.length) {
                dispatch(FollowAction.fetchSpecificHydroObservatoryFollowResult([stationId])).then(result => setObsResults(result))
            } else {
                const result = [hydroObservatoryFollowResults.find(ofr => ofr.id === stationId)] || []
                setObsResults(result)
            }
        } else if (stationType === STATION_TYPE_NAME.pluviometry) {
            dispatch(HomeAction.fetchPluviometer(stationId))
            if (!pluviometerAllThresholds.length) {
                dispatch(FollowAction.fetchPluviometerAllThresholds())
            }
            if (!pluviometryDataTypes.length) {
                dispatch(FollowAction.fetchPluviometryDataTypes())
            }
            if (!pluvioObservatoryFollowResults.length) {
                dispatch(FollowAction.fetchSpecificPluvioObservatoryFollowResult([stationId])).then(result => setObsResults(result))
            } else {
                const result = [pluvioObservatoryFollowResults.find(ofr => ofr.id === stationId)] || []
                setObsResults(result)
            }
        } else if (stationType === STATION_TYPE_NAME.quality) {
            dispatch(HomeAction.fetchQualitometer(stationId))
            if (!qualitoObservatoryFollowResults.length) {
                dispatch(FollowAction.fetchSpecificQualitoObservatoryFollowResult([stationId])).then(result => setObsResults(result))
            } else {
                const result = [qualitoObservatoryFollowResults.find(ofr => ofr.id === stationId)] || []
                setObsResults(result)
            }
            dispatch(FollowAction.fetchParameters())
            dispatch(FollowAction.fetchUnits())
        }

        if (!watermasses.length) {
            dispatch(FollowAction.fetchWatermasses())
        }
        if (!productionUnits.length) {
            dispatch(HomeAction.fetchProductionUnits())
        }
        if (!resources.length) {
            dispatch(ResourcesAction.fetchResources())
        }
        return () => {
            dispatch(HomeActionConstant.resetFollow())
            dispatch(HomeActionConstant.resetLinkedStations())

            setSimpleSheet(true)
            setUnitLinkedStation([])
            setPictures([])
            setDocuments([])
            setObsResults([])
        }
    }, [])

    const { ofr, station } = useMemo(() => {
        switch (stationType) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometry:
                return { ofr: obsResults.find(o => o.id === stationId) || {}, station: piezometer }
            case STATION_TYPE_NAME.hydrometry:
                return { ofr: obsResults.find(o => o.id === stationId) || {}, station: hydrologicalStation }
            case STATION_TYPE_NAME.pluviometry:
                return { ofr: obsResults.find(o => o.id === stationId) || {}, station: pluviometer }
            case STATION_TYPE_NAME.quality:
                return { ofr: obsResults.find(o => o.id === stationId) || {}, station: qualitometer }
            default:
                return { ofr: {}, station: {} }
        }
    }, [obsResults, hydrologicalStation, piezometer, pluviometer, qualitometer, stationId, stationType])

    useEffect(() => {
        if (!isNil(station?.code)) {
            dispatch(HomeAction.fetchLinkedStations([station.code], getStationTypeCodeFromType(stationType))).then(result => {
                const formattedResult = result.flatMap(s => new DtoAssociatedStation(s))
                const linkedInst = formattedResult.filter(s => s.typeName === STATION_TYPE_NAME.installation).map(s => ({ code: s.stationLinkedCode, stationType: s.typeName }))
                const st = stationType === OBSERVATORY_STATION_TYPE_NAME.catchment ? STATION_TYPE_NAME.piezometry : stationType
                const linkedStations = [{ code: station.code, stationType: st }, ...linkedInst]
                dispatch(HomeAction.fetchLinkedPictures(linkedStations)).then(setPictures)
                dispatch(HomeAction.fetchLinkedDocuments(linkedStations)).then(docs => setDocuments(docs.filter((d) => !d.name.endsWith('.kml') && !d.name.endsWith('.KML'))))
            })
        }
    }, [station])

    useTitle(() => [{
        title: i18n.follows,
        href: '/follows',
    }, {
        title: ofr?.name || station?.code || ofr.city || stationId,
        href: `/follows/${stationType}/${stationId}`,
    }], [ofr, station])

    const operator = useMemo(() => {
        const operatorsFiltered = uniqBy(station?.link_contributors || [], 'idContributor')?.map(c => {
            const contributor = contributors.find(cs => cs.id === c.idContributor)
            const type = getSandreLabel(sandreCodes, SANDRE.CONTRIBUTOR_TYPES, c.contributorType)
            return type === OPERATOR_TYPE_NAME ? contributor : undefined
        }).filter(c => !!c) || []
        const operatorsSorted = sortBy(operatorsFiltered, 'startDate')
        return !isNil(operatorsSorted[0]) ? (operatorsSorted[0]?.mnemonique || operatorsSorted[0]?.name || '') : undefined
    }, [contributors, sandreCodes, station?.link_contributors])

    const owner = useMemo(() => {
        const ownersFiltered = uniqBy(station?.link_contributors || [], 'idContributor')?.map(c => {
            const contributor = contributors.find(cs => cs.id === c.idContributor)
            const type = getSandreLabel(sandreCodes, SANDRE.CONTRIBUTOR_TYPES, c.contributorType)
            return type === OWNER_TYPE_NAME ? contributor : undefined
        }).filter(c => !!c) || []
        const ownersSorted = sortBy(ownersFiltered, 'startDate')
        return !isNil(ownersSorted[0]) ? (ownersSorted[0]?.mnemonique || ownersSorted[0]?.name || '') : undefined
    }, [contributors, sandreCodes, station?.link_contributors])

    const watermassesFormatted = useMemo(() => station?.link_watermasses?.map(w => `${w.watermassCode} - ${watermasses.find(wa => w.watermassCode === wa.code)?.name || ''}`).join(', '), [station?.link_watermasses, watermasses])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    const productionUnit = useMemo(() => haveUnitAccess ? productionUnits.find(p => p.name === ofr.productionUnit) || {} : {}, [haveUnitAccess, ofr.productionUnit, productionUnits])
    const resource = useMemo(() => haveResourceAccess ? resources.find(p => p.name === ofr.resource) || {} : {}, [haveResourceAccess, ofr.resource, resources])

    useEffect(() => {
        if (keys(productionUnit).length) {
            dispatch(HomeAction.fetchLinkedStation(productionUnit.code, STATION_TYPE_CONSTANT.productionUnit)).then(d => setUnitLinkedStation(filterObsLinkedStations(d)))
        }
    }, [productionUnit])

    const getInCrisisTag = allTags => {
        const inCrisisTag = allTags.filter(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || d.value?.includes('alerte'))
        return inCrisisTag.length ? inCrisisTag.map(tag => ({ ...tag, color: ['red', 'indianred', 'darkmagenta'].includes(tag.color) ? tag.color : 'indianred' })) : []
    }

    const getOnAlertTag = allTags => {
        const alertTag = allTags.filter(d => d.value?.includes('vigilance'))
        return alertTag.length ? alertTag.map(tag => ({ ...tag, color: 'orange' })) : []
    }

    const unitColor = useMemo(() => {
        const datas = unitLinkedStations.flatMap(l => {
            const findedOfr = obsResults.find(o => o.id === l.stationLinkedId)
            return findedOfr?.data?.map(d => ({ ...d, stationName: l.stationLinkedName || l.stationLinkedCode })) || []
        })
        const tagsInCrisis = getInCrisisTag(datas)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(datas)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : datas.filter(d => ['green', 'lightgreen'].includes(d.color))
        const tagsNoData = monitoringTags.length ? monitoringTags : datas.filter(d => ['grey', 'gray'].includes(d.color))
        return tagsNoData[0]?.color
    }, [unitLinkedStations, obsResults.length])

    const unitImg = getMarkerByStationType(STATION_TYPE_NAME.productionUnit, unitColor)

    const mapAndEventsPanel = (
        <>
            <LittleMap points={[station]} />
            <FollowEvents
                station={station}
                stationType={stationType}
                piezoObsResults={(stationType === OBSERVATORY_STATION_TYPE_NAME.catchment || stationType === STATION_TYPE_NAME.piezometry) && obsResults}
                hydroObsResults={stationType === STATION_TYPE_NAME.hydrometry && obsResults}
                pluvioObsResults={stationType === STATION_TYPE_NAME.pluviometry && obsResults}
                qualitoObsResults={stationType === STATION_TYPE_NAME.quality && obsResults}
            />
        </>
    )

    return (
        <Grid container sx={{ position: 'absolute' }}>
            <Grid container item xs={12} sx={{ backgroundColor: mainGrey, minHeight: 50, padding: mdMatches ? '1rem 3rem 0.7rem' : '1rem 3rem 0.7rem', borderTop: `solid 1px ${mainBlack}` }}>
                <Option
                    first
                    xs='auto'
                    sx={{ padding: '0 2rem' }}
                    selected={simpleSheet}
                    label={i18n.simpleCard}
                    onClick={() => setSimpleSheet(true)}
                />
                <Option
                    xs='auto'
                    sx={{ padding: '0 2rem' }}
                    selected={!simpleSheet}
                    label={i18n.detailsCard}
                    onClick={() => setSimpleSheet(false)}
                />
            </Grid>
            {keys(station).length ? (
                <Grid container item xs={12} justifyContent='space-between' sx={{ padding: mdMatches ? '10px 50px 10px 42px' : '10px 30px' }}>
                    <Grid container item md={8.5} xs={12} sx={{ maxHeight: '250px' }}>
                        <Grid container item xs={12} sx={{ borderBottom: !!simpleSheet && '1px solid rgba(233, 233, 233, 0.8)', padding: '0.5em 0 0.5em 0.5em' }}>
                            <Grid container direction='column' justifyContent='flex-start' item xs={ofr.data?.length ? 9 : 12}>
                                <Grid container sx={{ margin: '5px 0' }}>
                                    {ofr.city && (
                                        <Grid item sx={{ fontWeight: 'bold', fontSize: 20 }}>
                                            <span>{ofr.city || ''}</span>
                                        </Grid>
                                    )}
                                    {(ofr.name || station.name) && (
                                        <>
                                            <Grid item sx={{ fontWeight: 'bold', fontSize: 20, margin: '0 15px' }}>
                                                <span>-</span>
                                            </Grid>
                                            <Grid item sx={{ fontWeight: 'bold', fontSize: 20 }}>
                                                <span>{ofr.name || station.name}</span>
                                            </Grid>
                                        </>
                                    )}
                                    {(ofr.code || station.code) && (
                                        <>
                                            <Grid item sx={{ fontWeight: 'bold', fontSize: 20, margin: '0 15px' }}>
                                                <span>-</span>
                                            </Grid>
                                            <Grid item sx={{ fontWeight: 'bold', fontSize: 20 }}>
                                                <span>{ofr.code || station.code}</span>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                {!isNil(operator) && (
                                    <Grid sx={{ margin: '5px 0' }}>
                                        <span>{i18n.operator}: {operator}</span>
                                    </Grid>
                                )}
                                {!isNil(owner) && (
                                    <Grid sx={{ margin: '5px 0' }}>
                                        <span>{i18n.owner}: {owner}</span>
                                    </Grid>
                                )}
                                {(haveUnitAccess && ofr.productionUnit) && (
                                    <Grid container alignItems='center' sx={{ cursor: productionUnit.id && 'pointer', margin: '5px 0' }} onClick={() => productionUnit.id ? dispatch(push(`/productions/${productionUnit.id}`)) : {}}>
                                        {!!unitImg && (
                                            <Grid item>
                                                <img src={unitImg} alt={STATION_TYPE_NAME.productionUnit} style={{ height: '25px' }} />
                                            </Grid>
                                        )}
                                        <Grid item sx={{ marginLeft: '5px' }}>
                                            <span>{i18n.unit}: {ofr.productionUnit || ''}</span>
                                        </Grid>
                                    </Grid>
                                )}
                                {(ofr.aquifer || ofr.nature || ofr.fieldMode) && (
                                    <Grid container sx={{ margin: '5px 0' }}>
                                        {(haveResourceAccess && ofr.aquifer) && (
                                            <Grid item sx={{ cursor: resource.id && 'pointer' }} onClick={() => resource.id ? dispatch(push(`/resources/${resource.id}`)) : {}}>
                                                <span>{i18n.resource}: {ofr.aquifer || ''}</span>
                                            </Grid>
                                        )}
                                        {ofr.nature && (
                                            <>
                                                {!!ofr.aquifer && (
                                                    <Grid item sx={{ marginLeft: '5px' }}>
                                                        <span>-</span>
                                                    </Grid>
                                                )}
                                                <Grid item sx={{ marginLeft: '5px' }}>
                                                    <span>{i18n.nature}: {ofr.nature}</span>
                                                </Grid>
                                            </>
                                        )}
                                        {ofr.fieldMode && (
                                            <>
                                                {!!ofr.nature && (
                                                    <Grid item sx={{ marginLeft: '5px' }}>
                                                        <span>-</span>
                                                    </Grid>
                                                )}
                                                <Grid item sx={{ marginLeft: '5px' }}>
                                                    <span>{i18n.fieldMode}: {ofr.fieldMode}</span>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container item xs={ofr.data?.length ? 3 : 0}>
                                {!!ofr.data?.length && ofr.data.map(ofd => (
                                    <Grid
                                        item
                                        xs={12}
                                        key={`Alerte_${camelCase(ofd.value)}`}
                                        sx={{
                                            backgroundColor: ofd.color,
                                            padding: '3px',
                                            marginBottom: '3px',
                                            borderRadius: SMALL_RADIUS,
                                            textAlign: 'center',
                                            color: getTextColorByBackground(ofd.color),
                                            border: (ofd.color === 'white' || ofd.color === '#ffffff') && `solid 1px ${mainBlack}`,
                                            fontSize: '10.5px',
                                            lineHeight: 1.5,
                                            height: 'fit-content',
                                        }}
                                    >
                                        <span>{ofd.value}</span>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {!!pictures.length && (
                            <SmallPicturePanel pictures={pictures} />
                        )}
                        {!!documents.length && (
                            <FilePanel files={documents} hideTitle />
                        )}
                        {!simpleSheet && !!keys(station).length && (
                            <Grid container item xs={12} sx={{ border: `1px solid ${mainBlack}`, padding: '0.75rem', borderRadius: SMALL_RADIUS }}>
                                <Grid container item xs={12}>
                                    <Grid container item xs={6} rowGap={0.5} alignContent='flex-start'>
                                        <Grid item xs={12} sx={{ paddingBottom: '0.25rem', '& h4': { margin: 0 } }}>
                                            <h4>{i18n.descriptif} :</h4>
                                        </Grid>
                                        <Grid container item xs={12} alignItems='center' gap={1}>
                                            <Grid item xs='auto'>
                                                <span>{i18n.creationDate}:</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span>{getDate(station.creationDate)}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} alignItems='center' gap={1}>
                                            <Grid item xs='auto'>
                                                <span>{i18n.closeDate}:</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span>{getDate(station.closeDate)}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} alignItems='center' gap={1}>
                                            <Grid item xs='auto'>
                                                <span>{i18n.watermasses}:</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span>{watermassesFormatted}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6} rowGap={0.5} alignContent='flex-start'>
                                        <Grid item xs={12} sx={{ paddingBottom: '0.25rem', '& h4': { margin: 0 } }}>
                                            <h4>{i18n.landmarks} :</h4>
                                        </Grid>
                                        {!!hasValue(station.altitude) && (
                                            <Grid container item xs={12} alignItems='center' gap={1}>
                                                <Grid item xs='auto'>
                                                    <span>{i18n.height}:</span>
                                                </Grid>
                                                <Grid item xs>
                                                    <span>{station.link_altimetrySystems[station.link_altimetrySystems.length - 1]?.altitude}</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {!!hasValue(station.cote) && (
                                            <Grid container item xs={12} alignItems='center' gap={1}>
                                                <Grid item xs='auto'>
                                                    <span>{i18n.rating}:</span>
                                                </Grid>
                                                <Grid item xs>
                                                    <span>{station.cote}</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sx={{ paddingTop: '0.75rem', paddingBottom: '0.25rem', '& h4': { margin: 0 } }}>
                                            <h4>{i18n.coordinates} :</h4>
                                        </Grid>
                                        {!!hasValue(station.x) && (
                                            <Grid container item xs={12} alignItems='center' gap={1}>
                                                <Grid item xs='auto'>
                                                    <span>X:</span>
                                                </Grid>
                                                <Grid item xs>
                                                    <span>{station.x}</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {!!hasValue(station.y) && (
                                            <Grid container item xs={12} alignItems='center' gap={1}>
                                                <Grid item xs='auto'>
                                                    <span>Y:</span>
                                                </Grid>
                                                <Grid item xs>
                                                    <span>{station.y}</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid container item xs={12} alignItems='center' gap={1} sx={{ paddingTop: '0.75rem' }}>
                                        <Grid item xs='auto' sx={{ paddingBottom: '0.25rem', '& h4': { margin: 0 } }}>
                                            <h4>{i18n.comment} :</h4>
                                        </Grid>
                                        {!isNil(station.comment) && (
                                            <Grid item xs sx={{ '& span': { wordWrap: 'break-word' } }}>
                                                <span>{station.comment}</span>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container item xs={12}>
                            {(stationType === OBSERVATORY_STATION_TYPE_NAME.catchment || stationType === STATION_TYPE_NAME.piezometry) && <PiezoChartPanel station={station} stationType={stationType} />}
                            {(stationType === STATION_TYPE_NAME.hydrometry) && <HydroChartPanel station={station} />}
                            {(stationType === STATION_TYPE_NAME.pluviometry) && <PluvioChartPanel station={station} />}
                            {(stationType === STATION_TYPE_NAME.quality) && (qualityIsLoaded ? (
                                <Grid container item xs={12}>
                                    <Grid item xs={12}>
                                        <SituationHydrobioPanel qualitometer={station} />
                                    </Grid>
                                    <Grid container item xs={12} sx={{ padding: '0.5em' }}>
                                        <Grid item xs={12}>
                                            <ParameterPanel
                                                qualitometer={qualitometer}
                                                thresholds={[]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : <ProgressBar progress={qualityProgress} style={{ width: '100%' }} />)}
                        </Grid>
                        {stationType === OBSERVATORY_STATION_TYPE_NAME.catchment && (
                            <Grid container item xs={12}>
                                <InstallationLinkedProduction station={station} />
                            </Grid>
                        )}
                        {!mdMatches && <Grid item xs={12}>{mapAndEventsPanel}</Grid>}
                    </Grid>
                    {mdMatches && (
                        <Grid container item md={3.35} xs={12}>
                            <Grid item xs={12} sx={{ padding: '0 0 0.5em 0', boxShadow: '0px 0px 18px 0px rgb(0 0 0 / 12%)' }}>
                                {mapAndEventsPanel}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            ) : <ProgressBar indeterminate style={{ width: '80%' }} />}
        </Grid>
    )
}

Follow.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            stationType: PropTypes.string,
        }),
    }),
}

export default Follow
