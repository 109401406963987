import moment from 'moment'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { CHART_SELECTED_TIME } from 'pages/home/constants/HomeConstants'
import { HISTO, J365 } from 'pages/online/components/echart/ChartFollowContants'
import { getChartDate, getSubstractTime, setYOptions, yAutomaticScaleValues } from 'pages/online/components/echart/EChartUtils'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useAbortController from 'utils/customHook/useAbortController'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import FollowAction from '../actions/FollowAction'
import { STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { concat, groupBy, isNil, keys, round, uniqBy } from 'lodash'
import { Grid } from '@mui/material'
import ChartTabs from 'pages/online/components/echart/ChartTabs'
import ProgressBar from 'components/progress/ProgressBar'
import { getDateWithHour, getDayDiff, getFullDate } from 'utils/DateUtil'
import { getColorFromPalette2, getEventColor, getRGBColor } from 'utils/ColorUtil'
import { hasValue } from 'utils/NumberUtil'
import { chunkWithWords } from 'utils/StringUtil'
import MultiChart from 'pages/online/components/echart/MultiChart'
import { authorizeExport } from 'utils/HabilitationUtil'
import DtoMeasureStats from '../dto/DtoMeasureStats'
import DtoEvent from '../dto/DtoEvent'
import DtoPiezometryStationMeasure from 'pages/online/referencials/dto/DtoPiezometryStationMeasure'
import { renderToString } from 'react-dom/server'
import { statusIcon } from 'utils/StatusUtil'
import { HYDRO } from 'pages/home/constants/HabilitationConstants'
import DtoHydrometricStation from 'pages/home/dto/DtoHydrometricStation'
import { getHardHydroDataTypes } from 'utils/HydroUtils'

const HYDROMETER_HEIGHT_TYPE = 4
const ROUND_VALUE = 3
const ALL = 'all'
const GROUPS_FUNC = {
    RAW: 'RAW',
    MAX: 'MAX',
    MIN: 'MIN',
    AVERAGE: 'AVERAGE',
}

const HydrometerChart = ({
    station: { id: stationId, name: stationName, code: stationCode },
    stats = [],
    stationsEvents = [],
    measures = [],
    stationThresholds = [],

    graphicHeight = 250,
    onFullScreen = () => {},
    defaultMinDate,
    defaultMaxDate,
}) => {
    const dataTypesId = useMemo(() => stats.map(stat => stat.typeId), [stats])

    const thresholds = stats.length ? stationThresholds.filter(t => parseInt(t.stationId) === stationId && dataTypesId.includes(parseInt(t.dataType))).map(t => {
        const stat = stats.find(h => h.typeId === parseInt(t.dataType))
        const unit = stat?.unit ? `${stat?.unit}]` : ''
        return {
            ...t,
            unit,
            gridName: stat?.label || t.dataType,
        }
    }) : []

    const events = stationsEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            return e.graph === '1'
        }
        return false
    })

    const getBarDataEvents = () => events.map(e => ({
        date: e.startDate || getDateWithHour(e.date, e.eventHour).valueOf(),
        value: 1,
        color: getRGBColor(getEventColor(e.eventType)),
        event: e,
    }))

    const eventsFormatted = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        gridName: i18n.events,
        dataList: getBarDataEvents(),
        type: 'bar',
        barWidth: '10px',
    }], [])

    const groupedMeasures = useMemo(() => {
        const measuresFiltered = measures.filter(m => !!m.measures.length)
        return groupBy(measuresFiltered, 'dataType')
    }, [measures])
    const groupedMeasuresKeys = useMemo(() => [...keys(groupedMeasures).filter(k => k === `${HYDROMETER_HEIGHT_TYPE}`), ...keys(groupedMeasures).filter(k => k !== `${HYDROMETER_HEIGHT_TYPE}`).sort((a, b) => parseInt(a) - parseInt(b))], [groupedMeasures])

    const getFilteredData = (data) => data.filter((m, i) => {
        const diffInDays = (m?.date && m[i + 1]?.date) ? getDayDiff(m.date, m[i + 1].date) : 0
        return (m.initialPoint !== 1 && diffInDays <= 31 && !isNil(m?.date)) ? [m] : [
            { ...m, value: undefined, date: moment(m.date).subtract(1, 'second').valueOf() },
            { ...m, marker: 'emptyCircle' },
        ]
    })

    const measuresFormatted = useMemo(() => groupedMeasuresKeys.flatMap((key, index) => {
        const values = groupedMeasures[key]
        return values.map(value => {
            const stat = stats.find(h => h.typeId === value.dataType)
            const unit = stat?.unit ? `${stat?.unit} ` : ''
            return {
                gridIndex: index + 1,
                name: stat.label || '',
                unit,
                color: parseInt(value.dataType) === HYDROMETER_HEIGHT_TYPE ? 'blue' : getColorFromPalette2(index),
                gridName: stat?.label || key,
                dataList: getFilteredData(value.measures),
            }
        })
    }), [groupedMeasures, groupedMeasuresKeys, stationName, stationCode, stats])

    const dataFormatted = [...eventsFormatted, ...measuresFormatted]

    const eventGrids = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        yOptions: {
            type: 'value',
            nameRotate: 0,
            nameGap: 20,
            minInterval: 1,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        xOptions: {
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        },
        gridOptions: {
            top: 25,
            left: '100px',
            height: 40,
        },
    }], [])

    const measuresGrids = useMemo(() => measuresFormatted.length ? groupedMeasuresKeys.map((key, index) => {
        const dataType = groupedMeasures[key]?.[0]?.dataType
        const stat = stats.find(h => h.typeId === dataType)
        const unit = stat?.unit ? `[${stat?.unit}] ` : ''
        return {
            gridIndex: index + 1,
            name: `${stat?.label || key} ${unit}`,
            dataType,
            gridOptions: {
                left: '100px',
                top: 35,
                height: graphicHeight,
            },
            yOptions: {
                ...setYOptions(null, yAutomaticScaleValues(concat(thresholds.filter(t => t.dataType === `${dataType}`).map(t => t.value), measuresFormatted[index].dataList.map(d => d.value)), 3)),
            },
        }
    }) : [], [graphicHeight, groupedMeasures, groupedMeasuresKeys, measuresFormatted, stats])

    const grids = [...eventGrids, ...measuresGrids]

    const formatTooltip = params => {
        if (keys(params[0].value[2]?.event)?.length) {
            return getFullDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [,, { event }] }) => {
                return `<br/>${marker} ${seriesName}: ${event.comment ? (chunkWithWords(event.comment, 40).replaceAll('\n', '<br />')) : ''}<br />
                ${hasValue(event.ns) ? `${i18n.staticLevelMeasure} : ${event.ns}m<br />` : ''}
                ${hasValue(event.nc) ? `${i18n.sensorInstantLevel} : ${event.nc}m<br />` : ''}`
            }).join('')
        }
        return getFullDate(params[0].axisValue) + params.filter(({ value: [, result] }) => !isNil(result)).map(({ marker, seriesName, value: [, result, statusObj], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, ROUND_VALUE)} ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`).join('')
    }

    return (
        <MultiChart
            data={dataFormatted}
            grids={grids}
            stationsEvents={stationsEvents}
            thresholds={thresholds}
            roundValue={ROUND_VALUE}
            footerHeight={70}
            exportName={stationName || stationCode || ''}
            withDataZoom
            withOtherLegend
            withYZoom
            withToolLine
            withToolMarker
            onFullScreen={onFullScreen}
            defaultDisplayMarker={false}
            tooltipFormatter={formatTooltip}
            defaultMinDate={defaultMinDate}
            defaultMaxDate={defaultMaxDate}
            withExport={authorizeExport(HYDRO)}
        />
    )
}

HydrometerChart.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            code: PropTypes.string,
        }),
    ]),
    stats: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    stationThresholds: PropTypes.array,
    graphicHeight: PropTypes.number,
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
}

const HydroChartPanel = ({
    station,
    graphicHeight = 250,
}) => {
    const {
        hydrometryThresholds,
    } = useSelector(store => ({
        hydrometryThresholds: store.FollowReducer.hydrometryThresholds,
    }), shallowEqual)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const lastSelectedTime = useAccountSetting(CHART_SELECTED_TIME, v => v === HISTO ? v : (v ? parseInt(v) : J365)) || J365
    const getDefaultMinDate = () => lastSelectedTime === HISTO ? undefined : getSubstractTime(lastSelectedTime)
    const getDefaultMaxDate = () => lastSelectedTime === HISTO ? moment().valueOf() : undefined

    const [time, setTime] = useState(lastSelectedTime || J365)
    const [minDate, setMinDate] = useState(getDefaultMinDate())
    const [maxDate, setMaxDate] = useState(getDefaultMaxDate())
    const [measures, setMeasures] = useState([])
    const [fullScreen, setFullScreen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)

    const [hydroStatistics, setHydroStatistics] = useState([])
    const [stationEvents, setStationEvents] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        initController()

        return () => {
            controllerRef.current.abort()

            setTime(J365)
            setMinDate(moment().subtract(J365, 'days').startOf('day').valueOf())
            setMaxDate(undefined)
            setMeasures([])
            setFullScreen(false)
            setDataLoaded(false)

            setHydroStatistics([])
            setStationEvents([])
        }
    }, [])

    const { progress, isLoaded } = useProgressDispatch(() => {
        const hydroThresholdPromise = !hydrometryThresholds.length ? [dispatch(FollowAction.fetchHydrometricThresholds())] : []
        return [
            ...hydroThresholdPromise,
            dispatch(FollowAction.fetchHydroStatistics(station.id)).then(({ payload }) => setHydroStatistics((payload || []))),
            dispatch(FollowAction.fetchStationsEvents({ type: STATION_TYPE_NAME.hydrometry, ids: [station.id] })).then(({ payload }) => setStationEvents((payload || []))),
        ]
    }, [])

    const allHydroStats = useMemo(() => uniqBy([...getHardHydroDataTypes().map(dt => ({ ...dt, typeId: dt.id })), ...hydroStatistics], 'typeId'), [hydroStatistics])
    const dataTypesId = useMemo(() => allHydroStats.map(stat => stat.typeId), [allHydroStats])

    const allThresholds = useMemo(() => hydrometryThresholds.filter(t => t.stationId === `${station.id}` && dataTypesId.includes(parseInt(t.dataType))), [dataTypesId, hydrometryThresholds, station.id])

    useEffect(() => {
        if (isLoaded) {
            setMeasures([])
            setDataLoaded(false)
            const defaultGroupMode = time !== HISTO ? ALL : GROUPS_FUNC.MAX
            const inputs = dataTypesId.map(typeId => ({
                stationId: station.id,
                dataType: typeId,
                groupFunc: defaultGroupMode,
                chartMode: true,
                startDate: minDate,
                endDate: maxDate,
            }))
            dispatch(FollowAction.loadHydroChronicMeasures(inputs, () => {}, controllerRef.current.signal)).then(data => {
                setMeasures(prev => [...prev, ...data])
                setDataLoaded(true)
            })
        }
    }, [isLoaded, time])

    const fullScreenStyle = fullScreen ? {
        position: 'fixed',
        top: 0,
        left: 0,
        width: `calc(${window.innerWidth}px - 6.2rem)`,
        height: `calc(${window.innerHeight}px - 6rem)`,
        zIndex: 9,
        marginLeft: '5.5rem',
        marginTop: '5.5rem',
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    const chartHeight = graphicHeight || window.innerHeight / 5 // !fullScreen ? graphicHeight : (window.innerHeight / 2.1)

    const { minDate: formattedMinDate, maxDate: formattedMaxDate } = getChartDate(minDate, maxDate, time)

    return (isLoaded) ? (
        <Grid container sx={fullScreenStyle}>
            <Grid item xs={12}>
                <ChartTabs
                    time={time}
                    onChangeTime={newTime => {
                        setMinDate(newTime.minDate)
                        setMaxDate(newTime.maxDate)
                        setTime(newTime.time)
                    }}
                    inprogress={!dataLoaded}
                />
            </Grid>
            <Grid item xs={12}>
                {!dataLoaded ? (
                    <ProgressBar indeterminate />
                ) : (
                    <HydrometerChart
                        station={station}
                        stats={allHydroStats}
                        stationsEvents={stationEvents}
                        measures={measures}
                        stationThresholds={allThresholds}

                        onFullScreen={() => setFullScreen(prevFullScreen => !prevFullScreen)}
                        defaultMinDate={formattedMinDate}
                        defaultMaxDate={formattedMaxDate}
                        graphicHeight={chartHeight}
                    />
                )}
            </Grid>
        </Grid>
    ) : <ProgressBar progress={progress} />
}

HydroChartPanel.propTypes = {
    station: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    idsHydroDataType: PropTypes.arrayOf(PropTypes.number),
    idsPluvioDataType: PropTypes.arrayOf(PropTypes.number),
    graphicHeight: PropTypes.number,
    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    instIds: PropTypes.arrayOf(PropTypes.number),
    withChroniclesFollowUp: PropTypes.bool,
    setInstallationTypesChart: PropTypes.func,
    setDataTypesChart: PropTypes.func,
    showTitle: PropTypes.bool,
}

export default HydroChartPanel