import { compact, isNil, sortBy, take, uniqBy, uniqWith } from 'lodash'
import PropTypes from 'prop-types'
import Popup from 'ol-ext/overlay/Popup'
import { DEFAULT_MAP_X_COORDINATE, DEFAULT_MAP_Y_COORDINATE, DEFAULT_MAP_ZOOM, OSM_BACKGROUND, RELIEF_BACKGROUND, ROADMAP_BACKGROUND, SATELLITE_BACKGROUND } from 'pages/home/constants/HomeConstants'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import useListIndexed from 'utils/customHook/useListIndexed'
import { getWGS84Coordinate } from 'utils/mapUtils/CoordinateUtils'
import { filterObsLinkedStations, getCardMarkerByStationType, getColorByStationType, getMarkerByStationType } from 'utils/StationUtils'
import { XYZ } from 'ol/source'
import { Feature, Map, View } from 'ol'
import { ScaleLine, Rotate, defaults as defaultControls, FullScreen, Zoom } from 'ol/control'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import { Point } from 'ol/geom'
import { fromLonLat, transform } from 'ol/proj'
import VectorLayer from 'ol/layer/Vector'
import { useMediaQuery } from '@mui/material'
import { Style, Icon as Marker } from 'ol/style'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import DtoAccountSettings from 'pages/account/dto/DtoAccountSettings'
import { getLogin } from 'utils/LocalStorageUtils'
import moment from 'moment'
import HomeAction from 'pages/home/actions/HomeAction'
import { aquasysLogoPath, aquasysPath } from 'conf/SieauConstants'
import LayerGroup from 'ol/layer/Group'
import { mainWhite, SMALL_RADIUS } from 'components/styled/Theme'
import { renderToString } from 'react-dom/server'
import PopupContent from './PopupContent'
import { getFullDateMini } from 'utils/DateUtil'
import GraphPopup from './GraphPopup'
import { push } from 'connected-react-router'

const OlMap = ({
    coords,
    selectedStations = [],
    selectedLayers = [],
    selectedBackground = OSM_BACKGROUND,
    layersOpacity = [],

    layersName = [],
    formattedLayers = [],
}) => {
    const {
        piezometersLight,
        hydrometers,
        pluviometers,
        qualitometersLight,
        productionUnits,
        cities,

        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,

        linkedStations,

        piezoLastMeasures,
        hydroLastMeasures,
        pluvioLastMeasures,

        piezometerAllThresholds,
        hydrometryThresholds,
        pluviometerAllThresholds,

        piezometryDataTypes,
        hydrometryDataTypes,
        pluviometryDataTypes,
    } = useSelector(store => ({
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        qualitometersLight: store.HomeReducer.qualitometersLight,
        productionUnits: store.HomeReducer.productionUnits,
        cities: store.ReferencialReducer.cities,

        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,

        linkedStations: store.HomeReducer.linkedStations,

        piezoLastMeasures: store.FollowReducer.piezoLastMeasures,
        hydroLastMeasures: store.FollowReducer.hydroLastMeasures,
        pluvioLastMeasures: store.FollowReducer.pluvioLastMeasures,

        piezometerAllThresholds: store.FollowReducer.piezometerAllThresholds,
        hydrometryThresholds: store.FollowReducer.hydrometryThresholds,
        pluviometerAllThresholds: store.FollowReducer.pluviometerAllThresholds,

        piezometryDataTypes: store.FollowReducer.piezometryDataTypes,
        hydrometryDataTypes: store.FollowReducer.hydrometryDataTypes,
        pluviometryDataTypes: store.FollowReducer.pluviometryDataTypes,
    }), shallowEqual)

    const dispatch = useDispatch()

    const userParamX = useAccountSetting(DEFAULT_MAP_X_COORDINATE)
    const userParamY = useAccountSetting(DEFAULT_MAP_Y_COORDINATE)
    const userParamZoom = useAccountSetting(DEFAULT_MAP_ZOOM)

    const applicationX = useApplicationSetting(DEFAULT_MAP_X_COORDINATE)
    const applicationY = useApplicationSetting(DEFAULT_MAP_Y_COORDINATE)
    const applicationZoom = useApplicationSetting(DEFAULT_MAP_ZOOM)

    const citiesIndex = useListIndexed(cities, 'id')

    const map = useRef()
    const markersLayer = useRef(new VectorSource({ features: [] }))
    const backgroundSource = useRef(new TileLayer({ zIndex: 0, source: new OSM() }))

    const popup = useRef()
    const [selectedFeature, setSelectedFeature] = useState(undefined)
    const [selectedStation, setSelectedStation] = useState(undefined)
    const [isOpen, setIsOpen] = useState(false)

    const createParameter = (parameter, value) => {
        return new DtoAccountSettings({
            login: getLogin(),
            parameter,
            updateDate: moment().valueOf(),
            value,
        })
    }

    const onChangeMap = (value) => {
        const zoom = value.getView().getZoom()
        const point = transform(value.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')
        const xCoordinate = point[0]
        const yCoordinate = point[1]

        const zoomParameter = createParameter(DEFAULT_MAP_ZOOM, `${zoom}`)
        const xParameter = createParameter(DEFAULT_MAP_X_COORDINATE, `${xCoordinate}`)
        const yParameter = createParameter(DEFAULT_MAP_Y_COORDINATE, `${yCoordinate}`)
        const settings = [xParameter, yParameter, zoomParameter]
        if (!(applicationX === `${xCoordinate}` && applicationY === `${yCoordinate}`)) {
            dispatch(HomeAction.updateMultipleSettings({ settings }))
        }
    }

    const getListOfOfr = (typeName) => {
        switch (typeName) {
            case STATION_TYPE_NAME.piezometry:
                return piezoObservatoryFollowResults
            case STATION_TYPE_NAME.hydrometry:
                return hydroObservatoryFollowResults
            case STATION_TYPE_NAME.pluviometry:
                return pluvioObservatoryFollowResults
            case STATION_TYPE_NAME.quality:
                return qualitoObservatoryFollowResults
            default:
                return []
        }
    }

    const filteredLinkedStations = filterObsLinkedStations(linkedStations)

    const getInCrisisTag = allTags => {
        const inCrisisTag = [allTags.find(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || (d?.value || '').toLowerCase().includes('alerte'))].filter(d => !!d)
        return inCrisisTag.length ? inCrisisTag.map(tag => ({ ...tag, color: ['red', 'indianred', 'darkmagenta'].includes(tag.color) ? tag.color : 'indianred' })) : []
    }

    const getOnAlertTag = allTags => {
        const alertTag = [allTags.find(d => (d?.value || '').toLowerCase().includes('vigilance'))].filter(d => !!d)
        return alertTag.length ? alertTag.map(tag => ({ ...tag, color: 'orange', realColor: tag.color })) : []
    }

    const formatUnitPoint = (list, stationType, catchments) => list.map(l => {
        const links = filteredLinkedStations.filter(s => s.code === l.code)
        const uniqLinks = uniqWith(links, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
        const catchmentsIds = catchments.map(c => c.id)
        const allTags = uniqLinks.filter(ul => catchmentsIds.includes(ul.stationLinkedId)).flatMap(ul => {
            const listOfOfr = getListOfOfr(ul.typeName)
            const ofr = listOfOfr.find(o => o.id === ul.stationLinkedId)
            return ofr?.data?.map(d => ({ ...d, stationName: ul.stationLinkedName || ul.stationLinkedCode })) || []
        })
        const tagsInCrisis = getInCrisisTag(allTags)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(allTags)
        const tagsNoData = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
        const monitoringTags = tagsNoData.length ? tagsNoData : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
        return {
            ...l,
            marker: getMarkerByStationType(stationType, monitoringTags[0]?.color),
            icon: getCardMarkerByStationType(stationType, monitoringTags[0]?.color),
            stationType,
            color: getColorByStationType(stationType, monitoringTags[0]?.color),
        }
    })

    const formatCatchmentPoint = (list, stationType) => list.map(l => {
        const listOfOfr = getListOfOfr(l.typeName)
        const ofr = listOfOfr.find(o => o.id === l.id)
        const allTags = ofr?.data?.map(d => ({ ...d, stationName: l.name || l.code })) || []
        const tagsInCrisis = getInCrisisTag(allTags)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(allTags)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
        const tagsNoData = monitoringTags.length ? monitoringTags : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
        return {
            ...l,
            marker: getMarkerByStationType(stationType, tagsNoData[0]?.color),
            icon: getCardMarkerByStationType(stationType, tagsNoData[0]?.color),
            stationType,
            color: getColorByStationType(stationType, tagsNoData[0]?.color),
        }
    })

    const formatPoint = (list, stationType) => list.map(l => ({
        ...l,
        marker: getMarkerByStationType(stationType),
        icon: getCardMarkerByStationType(stationType),
        stationType,
        color: getColorByStationType(stationType),
    }))

    const points = useMemo(() => {
        const catchmentsIds = piezoObservatoryFollowResults.filter(p => p.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment).map(p => p.id)
        const catchments = piezometersLight.filter(piezo => catchmentsIds.includes(piezo.id))
        const piezos = piezometersLight.filter(piezo => !catchmentsIds.includes(piezo.id))
        const catchmentStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.CATCHMENT) ? formatCatchmentPoint(catchments, OBSERVATORY_STATION_TYPE_NAME.catchment) : []
        const piezoStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.PIEZOMETER) ? [...catchmentStations, ...formatPoint(piezos, STATION_TYPE_NAME.piezometry)] : catchmentStations
        const hydroStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.HYDROMETRIC_STATION) ? [...piezoStations, ...formatPoint(hydrometers, STATION_TYPE_NAME.hydrometry)] : piezoStations
        const pluvioStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.PLUVIOMETER) ? [...hydroStations, ...formatPoint(pluviometers, STATION_TYPE_NAME.pluviometry)] : hydroStations
        const qualitoStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.QUALITOMETER) ? [...pluvioStations, ...formatPoint(qualitometersLight, STATION_TYPE_NAME.quality)] : pluvioStations
        return selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.PRODUCTION_UNIT) ? [...qualitoStations, ...formatUnitPoint(productionUnits, STATION_TYPE_NAME.productionUnit, catchments)] : qualitoStations
    }, [selectedStations])

    const allPoints = useMemo(() => coords ? [{ x: coords.long, y: coords.lat, projection: 16, currentPosition: true }, ...points] : points, [points])

    useEffect(() => {
        switch (selectedBackground) {
            case ROADMAP_BACKGROUND:
                backgroundSource.current.setSource(new XYZ({
                    url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
                }))
                break
            case RELIEF_BACKGROUND:
                backgroundSource.current.setSource(new XYZ({
                    url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
                }))
                break
            case SATELLITE_BACKGROUND:
                backgroundSource.current.setSource(new XYZ({
                    attributions: ['Tiles &copy Esri &mdash Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'],
                    url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                }))
                break
            case OSM_BACKGROUND:
            default:
                backgroundSource.current.setSource(new OSM())
        }
    }, [selectedBackground])

    // Set selected stations dynamically
    const getDatasByStationType = st => {
        switch (st) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_CONSTANT.piezometer:
            case STATION_TYPE_NAME.piezometer:
            case STATION_TYPE_NAME.piezometry:
                return [piezoLastMeasures, piezometerAllThresholds, piezometryDataTypes]
            case STATION_TYPE_CONSTANT.hydrometricStation:
            case STATION_TYPE_NAME.hydrometricStation:
            case STATION_TYPE_NAME.hydrometry:
                return [hydroLastMeasures, hydrometryThresholds, hydrometryDataTypes]
            case STATION_TYPE_CONSTANT.pluviometer:
            case STATION_TYPE_NAME.pluviometer:
            case STATION_TYPE_NAME.pluviometry:
                return [pluvioLastMeasures, pluviometerAllThresholds, pluviometryDataTypes]
            default:
                return [[], [], []]
        }
    }

    const getFilteredThresholds = (station, thresholds, stationType) => {
        if (stationType === STATION_TYPE_NAME.hydrometry) {
            return thresholds.filter(threshold => parseInt(threshold.stationId, 10) === station.id)
        }
        return thresholds.filter(threshold => threshold.code === station.code)
    }

    useEffect(() => {
        const allMarkersLayers = allPoints.map(point => {
            if (point.x && point.y) {
                const city = citiesIndex[point.townCode]
                const coordinates = getWGS84Coordinate(point)

                const [lastMeasures, thresholds, dataTypes] = getDatasByStationType(point.stationType)

                const lastMeasureFormatted = uniqBy(lastMeasures.filter(stationData => stationData.id === point.id), 'dataType').map(lm => ({
                    ...lm,
                    unit: lm?.dataType ? dataTypes.find(dt => dt.id === lm.dataType)?.unit : '',
                    date: lm.lastMeasureDate && getFullDateMini(lm.lastMeasureDate),
                    dataTypeLabel: lm?.dataType ? dataTypes.find(dt => dt.id === lm.dataType)?.label : '',
                }))

                const thresholdStation = sortBy(getFilteredThresholds(point, thresholds, point.stationType).map(th => ({
                    ...th,
                    unit: dataTypes.find(dt => `${dt.id}` === th.dataType)?.unit,
                })), ['value'])

                return new Feature({
                    geometry: new Point(fromLonLat(coordinates)),
                    station: point,
                    code: point.code,
                    color: point.color,
                    name: point.name,
                    town: city?.name,
                    lastMeasures: lastMeasureFormatted,
                    thresholds: thresholdStation,
                    marker: point.marker,
                    icon: point.icon,
                    isStation: true,
                })
            }
            return null
        }).filter(ml => !!ml)

        markersLayer.current.forEachFeature(feature => (
            markersLayer.current.removeFeature(feature)
        ))

        if (allMarkersLayers?.length) {
            markersLayer.current.addFeatures(allMarkersLayers)
        }

        setSelectedFeature(undefined)
    }, [allPoints])

    // Set opacity and selected layers dynamically
    const setLayerVisibility = (layerGroup) => {
        layerGroup.getLayers().forEach(mapLayer => {
            if (mapLayer instanceof LayerGroup) {
                setLayerVisibility(mapLayer)
            } else {
                const layerId = mapLayer.get('id')
                const layerName = mapLayer.get('layerName')
                if (layersName.includes(layerName)) {
                    mapLayer.setVisible(selectedLayers.includes(`${layerId}`))
                    mapLayer.setOpacity(layersOpacity.find(lo => lo.layerName === layerName)?.opacity ?? 1)
                }
            }
        })
    }

    useEffect(() => {
        if (map.current) {
            setLayerVisibility(map.current)
        }
    }, [layersOpacity, selectedLayers])

    useEffect(() => {
        // Layers Stations
        const layersStations = new VectorLayer({
            zIndex: 8,
            source: markersLayer.current,
            style: feature => new Style({
                image: new Marker({
                    anchor: [0.5, 1],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    scale: 0.75,
                    src: feature.getProperties().marker,
                }),
            }),
        })

        // Center and zoom
        const valueX = isNil(userParamX) ? applicationX : userParamX
        const valueY = isNil(userParamY) ? applicationY : userParamY
        const valueZoom = isNil(userParamZoom) ? applicationZoom : userParamZoom

        const envX = isNil(valueX) ? process.env.REACT_APP_MAP_X : valueX
        const envY = isNil(valueY) ? process.env.REACT_APP_MAP_Y : valueY
        const envZoom = isNil(valueZoom) ? process.env.REACT_APP_MAP_ZOOM : valueZoom

        const defaultCenter = fromLonLat([Number(envX), Number(envY)])
        const defaultZoom = Number(envZoom)

        const wgs84Sites = compact(allPoints.map((point) => {
            if (point.x && point.y) {
                return getWGS84Coordinate(point)
            }
            return null
        }))

        const sumX = wgs84Sites.map((coord) => coord[0]).reduce((a, b) => a + b, 0) || 2.549
        const sumY = wgs84Sites.map((coord) => coord[1]).reduce((a, b) => a + b, 0) || 47.233
        const center = coords && coords.long && coords.lat ? fromLonLat([coords.long, coords.lat]) : (!isNaN(defaultCenter?.[0]) && !isNaN(defaultCenter?.[1])) ? defaultCenter : fromLonLat([sumX / (wgs84Sites.length || 1), sumY / (wgs84Sites.length || 1)])

        // Popup
        popup.current = new Popup({
            positioning: 'bottom-left',
        })

        // Set map
        map.current = new Map({
            target: 'map',
            layers: [
                backgroundSource.current,
                new LayerGroup({
                    layers: [layersStations],
                }),
                new LayerGroup({
                    layers: formattedLayers,
                }),
            ],
            view: new View({
                center,
                zoom: coords?.zoom ?? (!isNaN(defaultZoom) ? defaultZoom : 6),
            }),
            controls: defaultControls().extend([
                new ScaleLine({
                    units: 'metric',
                }),
                new Rotate(),
                new Zoom({
                    className: 'ol-zoom ol-custom-zoom',
                    zoomInTipLabel: 'Zoom +',
                    zoomOutTipLabel: 'Zoom -',
                }),
                new FullScreen({
                    className: 'ol-full-screen',
                }),
            ]),
            overlays: [popup.current],
        })
    }, [])

    const findFeature = (e) => {
        return map.current.forEachFeatureAtPixel(e.pixel, (feature) => {
            const listFeatures = feature.get('features') ?? [feature]
            if (listFeatures.length) {
                return take(listFeatures, 10).map(f => f.getProperties()).filter(lf => lf.isStation)
            }
            return null
        })
    }

    const popupMoveEvent = (e) => {
        const featureSelected = findFeature(e)
        map.current.getViewport().style.cursor = isNil(featureSelected) ? '' : 'pointer'
        if ((isNil(featureSelected) || !featureSelected.length) && isNil(selectedFeature)) {
            popup.current.hide()
            return
        }
        const feature = !isNil(selectedFeature) ? [selectedFeature] : featureSelected
        const coord = selectedFeature?.coordinate ?? e.coordinate
        if (feature[0]?.name) {
            document.documentElement.style.setProperty('--popup-border-color', (feature[feature.length - 1]?.color || mainWhite))

            popup.current.show(coord, renderToString(
                <PopupContent
                    features={feature}
                    onClick={() => {
                        const station = feature[0].station
                        if ([OBSERVATORY_STATION_TYPE_NAME.catchment,
                            STATION_TYPE_NAME.piezometry,
                            STATION_TYPE_NAME.hydrometry,
                            STATION_TYPE_NAME.pluviometry].includes(station.stationType)) {
                            setSelectedStation(station)
                            setIsOpen(true)
                        } else {
                            const path = station.stationType === STATION_TYPE_NAME.productionUnit ? `/productions/${station.id}` : `/follows/${station.stationType}/${station.id}`
                            dispatch(push(path))
                        }
                    }}
                />,
            ))
        }
    }

    const popupClickEvent = (e) => {
        const featureSelected = findFeature(e)
        if (featureSelected?.length) {
            setSelectedFeature({ ...featureSelected[0], coordinate: e.coordinate })
        } else {
            setSelectedFeature(undefined)
        }
    }

    useEffect(() => {
        const pointerMoveListener = (event) => {
            popupMoveEvent(event)
        }
        map.current.on('pointermove', pointerMoveListener)

        const singleClickListener = (event) => {
            popupClickEvent(event)
        }

        map.current.on('singleclick', singleClickListener)

        const moveEndListener = () => {
            onChangeMap(map.current)
        }
        map.current.on('moveend', moveEndListener)

        return () => {
            map.current.un('pointermove', pointerMoveListener)
            map.current.un('singleclick', singleClickListener)
            map.current.un('moveend', moveEndListener)
        }
    }, [selectedFeature])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    const onClosePopup = () => {
        setIsOpen(false)
        setSelectedStation(undefined)
    }

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div id='map' style={{ width: '100%', height: mdMatches ? '100%' : '40vh', borderRadius: SMALL_RADIUS, overflow: 'hidden' }} />
            <div style={{ position: 'absolute', bottom: '2rem', right: '0.5rem' }}>
                <a href={aquasysPath} target='_blank' rel='noopener noreferrer'>
                    <img src={aquasysLogoPath} alt='logo Aquasys' width='auto' height='30px' />
                </a>
            </div>
            <GraphPopup
                selectedStation={selectedStation}
                isOpen={isOpen}
                onClose={onClosePopup}
                onClick={() => {
                    const path = `/follows/${selectedStation.stationType}/${selectedStation.id}`
                    onClosePopup()
                    dispatch(push(path))
                }}
            />
        </div>
    )
}

OlMap.propTypes = {
    coords: PropTypes.shape({
        long: PropTypes.number,
        lat: PropTypes.number,
        zoom: PropTypes.number,
    }),
    selectedStations: PropTypes.arrayOf(PropTypes.string),
    selectedLayers: PropTypes.arrayOf(PropTypes.string),
    layersOpacity: PropTypes.arrayOf(PropTypes.shape({
        layerName: PropTypes.string,
        opacity: PropTypes.number,
    })),
    selectedBackground: PropTypes.string,
    layersName: PropTypes.arrayOf(PropTypes.string),
    formattedLayers: PropTypes.arrayOf(PropTypes.shape({})),
}

export default OlMap