import React from 'react'
import PropTypes from 'prop-types'
import { compact, orderBy, slice } from 'lodash'
import CmsAction from 'pages/cms/actions/CmsAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DocumentCard from './DocumentCard'
import i18n from 'simple-react-i18n'
import { mainWhite, SMALL_RADIUS } from 'components/styled/Theme'
import { chartGrey, mainBlack } from 'components/styled/Theme'
import { Grid, Icon } from '@mui/material'
import ProgressBar from 'components/progress/ProgressBar'
import { push } from 'connected-react-router'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { STATUS } from 'pages/cms/constants/CmsConstants'

const ActualitiesCard = ({
    width = '100%',
    height,
}) => {
    const actualitiesId = useApplicationSetting('categoriesCmsActualites', setting => setting ? parseInt(setting) : undefined)

    const {
        cmsEvents,
        cmsCategories,
        cmsTitle,
    } = useSelector(store => ({
        cmsEvents: store.CmsReducer.cmsEvents,
        cmsCategories: store.CmsReducer.cmsCategories,
        cmsTitle: store.CmsReducer.cmsTitle,
    }), shallowEqual)

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => compact([
        !cmsEvents.length && dispatch(CmsAction.fetchCMSEvents()),
        !cmsCategories.length && dispatch(CmsAction.fetchCMSCategories()),
    ]), [])

    const panelHeight = height || `calc(100vh - ${cmsTitle.id ? '266px' : '236px'})`

    return (
        <div style={{ width, backgroundColor: mainWhite, height: panelHeight, borderRadius: SMALL_RADIUS, boxShadow: '0px 0px 26px 0px rgb(0 0 0 / 12%)' }}>
            <Grid container sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                <Grid
                    container item xs={12}
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        fontSize: '20px',
                        backgroundColor: mainBlack,
                        color: mainWhite,
                        fontWeight: '600',
                        padding: '0.5rem 1rem',
                        borderRadius: `${SMALL_RADIUS} ${SMALL_RADIUS} 0 0`,
                    }}
                >
                    <Grid item>
                        <span>{i18n.actualities}</span>
                    </Grid>
                    <Grid item>
                        <Icon
                            className='clickable'
                            onClick={() => dispatch(push('/documents/actualities'))}
                        >
                            more_horiz
                        </Icon>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sx={{ maxHeight: 'calc(100% - 48px)', overflowY: 'auto' }}>
                    {isLoaded ? slice(orderBy(cmsEvents.filter((c) => c.idCategory === actualitiesId && c.status === STATUS.PUBLISHED), 'dateDebut', 'desc'), 0, 6).map((d, i) => (
                        <Grid container item xs={12} sx={{ borderTop: i !== 0 && `solid 1px ${chartGrey}` }}>
                            <DocumentCard cms={d} little />
                        </Grid>
                    )) : (
                        <ProgressBar progress={progress} title={`${i18n.actualitiesInProgress} ...`} />
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

ActualitiesCard.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}

export default ActualitiesCard